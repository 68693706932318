/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createEntitlementsResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type EntitlementsResponse, type ErrorResponse, type InternalErrorResponse } from '../../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/subscriptions/entitlements
 */
export interface EntitlementsRequestBuilder extends BaseRequestBuilder<EntitlementsRequestBuilder> {
    /**
     * Gets the entitlements for the Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<EntitlementsResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<EntitlementsRequestBuilderGetQueryParameters> | undefined) : Promise<EntitlementsResponse | undefined>;
    /**
     * Gets the entitlements for the Organization
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<EntitlementsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
}
/**
 * Gets the entitlements for the Organization
 */
export interface EntitlementsRequestBuilderGetQueryParameters {
    /**
     * SubscriptionId for a specific subscription
     */
    subscriptionId?: string;
}
/**
 * Uri template for the request builder.
 */
export const EntitlementsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/subscriptions/entitlements?subscriptionId={subscriptionId}";
/**
 * Metadata for all the requests in the request builder.
 */
export const EntitlementsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: EntitlementsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createEntitlementsResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
