/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createDataMappingFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, serializeCreateOrUpdateDataMappingRequest, serializeDataMapping, type CreateOrUpdateDataMappingRequest, type DataMapping, type ErrorResponse, type InternalErrorResponse } from '../../models/';
// @ts-ignore
import { DatamappingsItemRequestBuilderRequestsMetadata, type DatamappingsItemRequestBuilder } from './item/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/datamappings
 */
export interface DatamappingsRequestBuilder extends BaseRequestBuilder<DatamappingsRequestBuilder> {
    /**
     * Gets an item from the MomentumAPI.api.datamappings.item collection
     * @param id Unique identifier of the item
     * @returns {DatamappingsItemRequestBuilder}
     */
     byId(id: string) : DatamappingsItemRequestBuilder;
    /**
     * List Data Mappings
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<DataMapping[]>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<DataMapping[] | undefined>;
    /**
     * Create or Update Data Mapping
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<DataMapping>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     put(body: CreateOrUpdateDataMappingRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<DataMapping | undefined>;
    /**
     * List Data Mappings
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Create or Update Data Mapping
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: CreateOrUpdateDataMappingRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const DatamappingsRequestBuilderUriTemplate = "{+baseurl}/api/dataMappings";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const DatamappingsRequestBuilderNavigationMetadata: Record<Exclude<keyof DatamappingsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byId: {
        requestsMetadata: DatamappingsItemRequestBuilderRequestsMetadata,
        pathParametersMappings: ["id"],
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const DatamappingsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: DatamappingsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendCollection",
        responseBodyFactory:  createDataMappingFromDiscriminatorValue,
    },
    put: {
        uriTemplate: DatamappingsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createDataMappingFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateOrUpdateDataMappingRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
