<script setup></script>
<template>
    <Suspense>
        <template #default>
            <RouterView />
        </template>
        <template #fallback>
            <div>Loading...</div>
        </template>
    </Suspense>
</template>
<style scoped></style>