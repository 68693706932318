/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createListOrganizationSalesRepsResponseFromDiscriminatorValue, createOkFromDiscriminatorValue, createSalesRepFromDiscriminatorValue, serializeCreateSalesRepRequest, serializeDeleteSalesRepRequest, serializeOk, serializeSalesRep, type CreateSalesRepRequest, type DeleteSalesRepRequest, type ErrorResponse, type InternalErrorResponse, type ListOrganizationSalesRepsResponse, type Ok, type SalesRep } from '../../../models/';
// @ts-ignore
import { ActivateRequestBuilderRequestsMetadata, type ActivateRequestBuilder } from './activate/';
// @ts-ignore
import { DeactivateRequestBuilderRequestsMetadata, type DeactivateRequestBuilder } from './deactivate/';
// @ts-ignore
import { ExistsRequestBuilderRequestsMetadata, type ExistsRequestBuilder } from './exists/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/salesreps
 */
export interface SalesrepsRequestBuilder extends BaseRequestBuilder<SalesrepsRequestBuilder> {
    /**
     * The activate property
     */
    get activate(): ActivateRequestBuilder;
    /**
     * The deactivate property
     */
    get deactivate(): DeactivateRequestBuilder;
    /**
     * The exists property
     */
    get exists(): ExistsRequestBuilder;
    /**
     * Deletes a Sales Rep in the Organization.
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     delete(body: DeleteSalesRepRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * List Sales Reps that belong to an Organization with paging and sorting options
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ListOrganizationSalesRepsResponse[]>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<SalesrepsRequestBuilderGetQueryParameters> | undefined) : Promise<ListOrganizationSalesRepsResponse[] | undefined>;
    /**
     * Create a Sales Rep for an Organization
     * @param body Represents a request to create a Sales Representative.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<SalesRep>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     put(body: CreateSalesRepRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<SalesRep | undefined>;
    /**
     * Deletes a Sales Rep in the Organization.
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toDeleteRequestInformation(body: DeleteSalesRepRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * List Sales Reps that belong to an Organization with paging and sorting options
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<SalesrepsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
    /**
     * Create a Sales Rep for an Organization
     * @param body Represents a request to create a Sales Representative.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: CreateSalesRepRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * List Sales Reps that belong to an Organization with paging and sorting options
 */
export interface SalesrepsRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    /**
     * Gets or sets the field to sort on. Default value is "RepNumber".
     */
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const SalesrepsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/salesreps?pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const SalesrepsRequestBuilderNavigationMetadata: Record<Exclude<keyof SalesrepsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    activate: {
        requestsMetadata: ActivateRequestBuilderRequestsMetadata,
    },
    deactivate: {
        requestsMetadata: DeactivateRequestBuilderRequestsMetadata,
    },
    exists: {
        requestsMetadata: ExistsRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const SalesrepsRequestBuilderRequestsMetadata: RequestsMetadata = {
    delete: {
        uriTemplate: SalesrepsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeDeleteSalesRepRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
    get: {
        uriTemplate: SalesrepsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendCollection",
        responseBodyFactory:  createListOrganizationSalesRepsResponseFromDiscriminatorValue,
    },
    put: {
        uriTemplate: SalesrepsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createSalesRepFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateSalesRepRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
