/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { CheckoutPageRequestBuilderRequestsMetadata, type CheckoutPageRequestBuilder } from './checkoutPage/';
// @ts-ignore
import { CreatedRequestBuilderRequestsMetadata, type CreatedRequestBuilder } from './created/';
// @ts-ignore
import { EntitlementsRequestBuilderRequestsMetadata, type EntitlementsRequestBuilder } from './entitlements/';
// @ts-ignore
import { PortalSessionLinkRequestBuilderRequestsMetadata, type PortalSessionLinkRequestBuilder } from './portalSessionLink/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations/subscriptions
 */
export interface SubscriptionsRequestBuilder extends BaseRequestBuilder<SubscriptionsRequestBuilder> {
    /**
     * The checkoutPage property
     */
    get checkoutPage(): CheckoutPageRequestBuilder;
    /**
     * The created property
     */
    get created(): CreatedRequestBuilder;
    /**
     * The entitlements property
     */
    get entitlements(): EntitlementsRequestBuilder;
    /**
     * The portalSessionLink property
     */
    get portalSessionLink(): PortalSessionLinkRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const SubscriptionsRequestBuilderUriTemplate = "{+baseurl}/api/organizations/subscriptions";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const SubscriptionsRequestBuilderNavigationMetadata: Record<Exclude<keyof SubscriptionsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    checkoutPage: {
        requestsMetadata: CheckoutPageRequestBuilderRequestsMetadata,
    },
    created: {
        requestsMetadata: CreatedRequestBuilderRequestsMetadata,
    },
    entitlements: {
        requestsMetadata: EntitlementsRequestBuilderRequestsMetadata,
    },
    portalSessionLink: {
        requestsMetadata: PortalSessionLinkRequestBuilderRequestsMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
