// stores/errorStore.ts
import { defineStore } from 'pinia';
import { useToast } from "primevue/usetoast";
import { ref } from 'vue';
import {  type ProblemDetails } from '@/types/ProblemDetails'

export const useErrorStore = defineStore('errorStore', () => {
 
  const toast = useToast();
  const errors = ref<Array<ProblemDetails>>([]);
  const addError = (problemDetails: ProblemDetails) => {
    
    console.log('ErrorStore addError:', problemDetails);
    errors.value.push(problemDetails);

    let detailMessage = '';
    
    // loop through the error in the problemDetails and add them to the toast
    for (let i = 0; i < problemDetails.errors?.length; i++) {
      console.log('ErrorStore addError:', problemDetails.errors[i].reason);
      detailMessage += problemDetails.errors[i].reason + '\n\n';
    }

    toast.add({severity:'error', summary:problemDetails.title, detail: detailMessage, life: 6000});
  };
  const clearErrors = () => {
    errors.value.splice(0, errors.value.length);
  };
  
  return {
    errors,
    addError,
    clearErrors
  };
 
});