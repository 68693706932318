/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOkFromDiscriminatorValue, serializeCreateManyProductsRequest, serializeOk, type CreateManyProductsRequest, type ErrorResponse, type InternalErrorResponse, type Ok } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/products/bulk
 */
export interface BulkRequestBuilder extends BaseRequestBuilder<BulkRequestBuilder> {
    /**
     * Create Many Products
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Ok>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     put(body: CreateManyProductsRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Ok | undefined>;
    /**
     * Create Many Products
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPutRequestInformation(body: CreateManyProductsRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const BulkRequestBuilderUriTemplate = "{+baseurl}/api/products/bulk";
/**
 * Metadata for all the requests in the request builder.
 */
export const BulkRequestBuilderRequestsMetadata: RequestsMetadata = {
    put: {
        uriTemplate: BulkRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createOkFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateManyProductsRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
