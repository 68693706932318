/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createProductFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type Product } from '../../../models/';
// @ts-ignore
import { ActivateRequestBuilderRequestsMetadata, type ActivateRequestBuilder } from './activate/';
// @ts-ignore
import { DeactivateRequestBuilderRequestsMetadata, type DeactivateRequestBuilder } from './deactivate/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/products/{sku}
 */
export interface WithSkuItemRequestBuilder extends BaseRequestBuilder<WithSkuItemRequestBuilder> {
    /**
     * The activate property
     */
    get activate(): ActivateRequestBuilder;
    /**
     * The deactivate property
     */
    get deactivate(): DeactivateRequestBuilder;
    /**
     * Delete a Product by SKU
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     delete(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Get a Product by SKU
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Product>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Product | undefined>;
    /**
     * Delete a Product by SKU
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toDeleteRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Get a Product by SKU
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const WithSkuItemRequestBuilderUriTemplate = "{+baseurl}/api/products/{sku}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const WithSkuItemRequestBuilderNavigationMetadata: Record<Exclude<keyof WithSkuItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    activate: {
        requestsMetadata: ActivateRequestBuilderRequestsMetadata,
    },
    deactivate: {
        requestsMetadata: DeactivateRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const WithSkuItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    delete: {
        uriTemplate: WithSkuItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json, application/problem+json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
    },
    get: {
        uriTemplate: WithSkuItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createProductFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
