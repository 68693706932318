import { useAuthStore} from '@/stores/authStore';

export async function requireAdminRole(to: any, from: any, next: any) {
  
  const { authenticated, admin } = useAuthStore();
  
  // check if the user has the admin role, if not redirect to not-authorized
  if (!authenticated) {
    return next('/home');
  }

  if (!admin) {
    return next('/not-authorized');
  }

  return next();
}