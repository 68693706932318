/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCustomer2FromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, type Customer2, type ErrorResponse, type InternalErrorResponse } from '../../../models/';
// @ts-ignore
import { ActivateRequestBuilderRequestsMetadata, type ActivateRequestBuilder } from './activate/';
// @ts-ignore
import { DeactivateRequestBuilderRequestsMetadata, type DeactivateRequestBuilder } from './deactivate/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/customers/{customerNumber}
 */
export interface WithCustomerNumberItemRequestBuilder extends BaseRequestBuilder<WithCustomerNumberItemRequestBuilder> {
    /**
     * The activate property
     */
    get activate(): ActivateRequestBuilder;
    /**
     * The deactivate property
     */
    get deactivate(): DeactivateRequestBuilder;
    /**
     * Delete a Customer by CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     delete(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<void>;
    /**
     * Get a Customer by CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Customer2>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<Customer2 | undefined>;
    /**
     * Delete a Customer by CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toDeleteRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
    /**
     * Get a Customer by CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const WithCustomerNumberItemRequestBuilderUriTemplate = "{+baseurl}/api/customers/{customerNumber}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const WithCustomerNumberItemRequestBuilderNavigationMetadata: Record<Exclude<keyof WithCustomerNumberItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    activate: {
        requestsMetadata: ActivateRequestBuilderRequestsMetadata,
    },
    deactivate: {
        requestsMetadata: DeactivateRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const WithCustomerNumberItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    delete: {
        uriTemplate: WithCustomerNumberItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json, application/problem+json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendNoResponseContent",
    },
    get: {
        uriTemplate: WithCustomerNumberItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCustomer2FromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
