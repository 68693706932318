<script setup lang="ts">
import { ref } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useAuthStore } from '@/stores/authStore';
import { handleGlobalError } from '@/composables/globalErrorHandler';

const visibleFull = ref(false);
const authStore =  useAuthStore()
const { layoutState } = useLayout();
const logout = async() => {
    
    console.log('logging out');
    await authStore.logUserOut();
}

const manageSubscriptionURL = ref('');
const manageSubscription = async() => {
    layoutState.profileSidebarVisible.value = false;
    try {
            const response  = await authStore.getPortalSessionLink();
            manageSubscriptionURL.value = response?.sessionUrl??'';
            visibleFull.value = true;
          }
          catch (error) {
            handleGlobalError(error);
          }
    }

const launchAPIDocumentation = async() => {
        layoutState.profileSidebarVisible.value = false;
        const readmeAuthResponse = await authStore.getReadmeLoginUrl();
        window.open(readmeAuthResponse?.readmeAuthUrl, 'docs');
    }

const initializePendo = () => { 
    window.pendo.initialize({
        visitor: {
            id: authStore.User?.id,
            email: authStore.User?.email,
            firstName: authStore.User?.given_name,
            lastName: authStore.User?.family_name,
        },
        account: {
            id: authStore.User?.id,
            accountName: authStore.User?.email,
            payingStatus: 'Free',
            businessTier: 'Free',
            industry: 'Tech',
            location: 'US',
        }
    });
}   
initializePendo();
</script>

<template>
    <Sidebar v-model:visible="visibleFull" :baseZIndex="1000" position="full">
         <iframe :src="manageSubscriptionURL" style="width: 100%; height: 100%; border: none;"></iframe>
    </Sidebar>
    <Sidebar v-model:visible="layoutState.profileSidebarVisible.value" position="right" class="layout-profile-sidebar w-full sm:w-25rem">
        
        <div class="flex flex-column mx-auto md:mx-0">
            <span class="mb-2 font-semibold">Welcome</span>
            <span class="text-color-secondary font-medium mb-5">{{authStore.User?.given_name}} {{authStore.User?.family_name}}</span>

            <ul class="list-none m-0 p-0">
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150">
                        <span>
                            <i class="pi pi-user text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Profile</span>
                            <p class="text-color-secondary m-0">Edit Your Profile</p>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150" @click="manageSubscription">
                        <span>
                            <i class="pi pi-credit-card text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Manage Subscription</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150" @click="launchAPIDocumentation" >
                        <span>
                            <i class="pi pi-box text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">API Documentation</span>
                        </div>
                    </a>
                </li>
                <li>
                    <a class="cursor-pointer flex surface-border mb-3 p-3 align-items-center border-1 surface-border border-round hover:surface-hover transition-colors transition-duration-150" @click="logout">
                        <span>
                            <i class="pi pi-power-off text-xl text-primary"></i>
                        </span>
                        <div class="ml-3">
                            <span class="mb-2 font-semibold">Sign Out</span>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
        
    </Sidebar>
</template>
