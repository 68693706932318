/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createShoppingCartFromDiscriminatorValue, serializeCreateCartRequest, serializeShoppingCart, type CreateCartRequest, type ErrorResponse, type InternalErrorResponse, type ShoppingCart } from '../../../models/';
// @ts-ignore
import { CompleteCheckoutRequestBuilderRequestsMetadata, type CompleteCheckoutRequestBuilder } from './completeCheckout/';
// @ts-ignore
import { type WithShoppingCartItemRequestBuilder, WithShoppingCartItemRequestBuilderNavigationMetadata } from './item/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts/{customerNumber-id}
 */
export interface CustomerNumberItemRequestBuilder extends BaseRequestBuilder<CustomerNumberItemRequestBuilder> {
    /**
     * The completeCheckout property
     */
    get completeCheckout(): CompleteCheckoutRequestBuilder;
    /**
     * Gets an item from the MomentumAPI.api.carts.item.item collection
     * @param shoppingCartId Unique identifier of the item
     * @returns {WithShoppingCartItemRequestBuilder}
     */
     byShoppingCartId(shoppingCartId: string) : WithShoppingCartItemRequestBuilder;
    /**
     * Get a ShoppingCart by UserId and CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ShoppingCart>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<CustomerNumberItemRequestBuilderGetQueryParameters> | undefined) : Promise<ShoppingCart | undefined>;
    /**
     * Creates a new Shopping Cart. If a Shopping Cart already exists for the Customer then a BadRequest will be returned.
     * @param body Represents a request to create a new shopping cart.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ShoppingCart>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: CreateCartRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ShoppingCart | undefined>;
    /**
     * Get a ShoppingCart by UserId and CustomerNumber
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<CustomerNumberItemRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
    /**
     * Creates a new Shopping Cart. If a Shopping Cart already exists for the Customer then a BadRequest will be returned.
     * @param body Represents a request to create a new shopping cart.
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: CreateCartRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Get a ShoppingCart by UserId and CustomerNumber
 */
export interface CustomerNumberItemRequestBuilderGetQueryParameters {
    userId?: string;
}
/**
 * Uri template for the request builder.
 */
export const CustomerNumberItemRequestBuilderUriTemplate = "{+baseurl}/api/carts/{customerNumber%2Did}?userId={userId}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const CustomerNumberItemRequestBuilderNavigationMetadata: Record<Exclude<keyof CustomerNumberItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    byShoppingCartId: {
        navigationMetadata: WithShoppingCartItemRequestBuilderNavigationMetadata,
        pathParametersMappings: ["shoppingCartId"],
    },
    completeCheckout: {
        requestsMetadata: CompleteCheckoutRequestBuilderRequestsMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const CustomerNumberItemRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: CustomerNumberItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createShoppingCartFromDiscriminatorValue,
    },
    post: {
        uriTemplate: CustomerNumberItemRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createShoppingCartFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateCartRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
