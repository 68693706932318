import { AccessTokenProvider, AllowedHostsValidator } from '@microsoft/kiota-abstractions';
import { useAuthStore } from '@/stores/authStore';
import { BaseBearerTokenAuthenticationProvider } from '@microsoft/kiota-abstractions';
import { FetchRequestAdapter } from '@microsoft/kiota-http-fetchlibrary';
import { createApiClient } from '@/api-client/apiClient';

export class ApiClientFactory {
    
    private authStore = useAuthStore();
    
    createClient(): ReturnType<typeof createApiClient> {
        const accessTokenProvider = new AuthStoreAccessTokenProvider(this.authStore);
        const authProvider = new BaseBearerTokenAuthenticationProvider(accessTokenProvider);
        const adapter = new FetchRequestAdapter(authProvider);
        adapter.baseUrl = process.env.VITE_MOMENTUM_API_BASE_URL || '';
        return createApiClient(adapter);
    }
}

class AuthStoreAccessTokenProvider implements AccessTokenProvider {
    private authStore: ReturnType<typeof useAuthStore>;

    constructor(authStore: ReturnType<typeof useAuthStore>) {
        this.authStore = authStore;
    }

    async getAuthorizationToken(url?: string, additionalAuthenticationContext?: Record<string, unknown>): Promise<string> {
        // Use the getAuthorizationToken method of authStore to get the token
        return  (await this.authStore).getAuthorizationToken();
    }

    getAllowedHostsValidator(): AllowedHostsValidator {
        // Implement this method as needed
        return {
            isAllowed: (url: string) => true
        };
    }
}