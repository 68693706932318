/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { AuthRequestBuilderNavigationMetadata, type AuthRequestBuilder } from './auth/';
// @ts-ignore
import { CartsRequestBuilderNavigationMetadata, type CartsRequestBuilder } from './carts/';
// @ts-ignore
import { CustomersRequestBuilderNavigationMetadata, CustomersRequestBuilderRequestsMetadata, type CustomersRequestBuilder } from './customers/';
// @ts-ignore
import { DatamappingsRequestBuilderNavigationMetadata, DatamappingsRequestBuilderRequestsMetadata, type DatamappingsRequestBuilder } from './datamappings/';
// @ts-ignore
import { OrganizationRequestBuilderRequestsMetadata, type OrganizationRequestBuilder } from './organization/';
// @ts-ignore
import { OrganizationsRequestBuilderNavigationMetadata, OrganizationsRequestBuilderRequestsMetadata, type OrganizationsRequestBuilder } from './organizations/';
// @ts-ignore
import { ProductsRequestBuilderNavigationMetadata, ProductsRequestBuilderRequestsMetadata, type ProductsRequestBuilder } from './products/';
// @ts-ignore
import { SalesrepsRequestBuilderNavigationMetadata, type SalesrepsRequestBuilder } from './salesreps/';
// @ts-ignore
import { type UsersRequestBuilder, UsersRequestBuilderNavigationMetadata, UsersRequestBuilderRequestsMetadata } from './users/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api
 */
export interface ApiRequestBuilder extends BaseRequestBuilder<ApiRequestBuilder> {
    /**
     * The auth property
     */
    get auth(): AuthRequestBuilder;
    /**
     * The carts property
     */
    get carts(): CartsRequestBuilder;
    /**
     * The customers property
     */
    get customers(): CustomersRequestBuilder;
    /**
     * The datamappings property
     */
    get datamappings(): DatamappingsRequestBuilder;
    /**
     * The organization property
     */
    get organization(): OrganizationRequestBuilder;
    /**
     * The organizations property
     */
    get organizations(): OrganizationsRequestBuilder;
    /**
     * The products property
     */
    get products(): ProductsRequestBuilder;
    /**
     * The salesreps property
     */
    get salesreps(): SalesrepsRequestBuilder;
    /**
     * The users property
     */
    get users(): UsersRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const ApiRequestBuilderUriTemplate = "{+baseurl}/api";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const ApiRequestBuilderNavigationMetadata: Record<Exclude<keyof ApiRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    auth: {
        navigationMetadata: AuthRequestBuilderNavigationMetadata,
    },
    carts: {
        navigationMetadata: CartsRequestBuilderNavigationMetadata,
    },
    customers: {
        requestsMetadata: CustomersRequestBuilderRequestsMetadata,
        navigationMetadata: CustomersRequestBuilderNavigationMetadata,
    },
    datamappings: {
        requestsMetadata: DatamappingsRequestBuilderRequestsMetadata,
        navigationMetadata: DatamappingsRequestBuilderNavigationMetadata,
    },
    organization: {
        requestsMetadata: OrganizationRequestBuilderRequestsMetadata,
    },
    organizations: {
        requestsMetadata: OrganizationsRequestBuilderRequestsMetadata,
        navigationMetadata: OrganizationsRequestBuilderNavigationMetadata,
    },
    products: {
        requestsMetadata: ProductsRequestBuilderRequestsMetadata,
        navigationMetadata: ProductsRequestBuilderNavigationMetadata,
    },
    salesreps: {
        navigationMetadata: SalesrepsRequestBuilderNavigationMetadata,
    },
    users: {
        requestsMetadata: UsersRequestBuilderRequestsMetadata,
        navigationMetadata: UsersRequestBuilderNavigationMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
