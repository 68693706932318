/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createCreateOrganizationResponseFromDiscriminatorValue, createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createOrganizationFromDiscriminatorValue, serializeCreateOrganizationRequest, serializeCreateOrganizationResponse, type CreateOrganizationRequest, type CreateOrganizationResponse, type ErrorResponse, type InternalErrorResponse, type Organization } from '../../models/';
// @ts-ignore
import { PriceTiersRequestBuilderRequestsMetadata, type PriceTiersRequestBuilder } from './priceTiers/';
// @ts-ignore
import { SalesrepsRequestBuilderNavigationMetadata, SalesrepsRequestBuilderRequestsMetadata, type SalesrepsRequestBuilder } from './salesreps/';
// @ts-ignore
import { SubscriptionsRequestBuilderNavigationMetadata, type SubscriptionsRequestBuilder } from './subscriptions/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/organizations
 */
export interface OrganizationsRequestBuilder extends BaseRequestBuilder<OrganizationsRequestBuilder> {
    /**
     * The priceTiers property
     */
    get priceTiers(): PriceTiersRequestBuilder;
    /**
     * The salesreps property
     */
    get salesreps(): SalesrepsRequestBuilder;
    /**
     * The subscriptions property
     */
    get subscriptions(): SubscriptionsRequestBuilder;
    /**
     * List Organizations with paging and sorting options
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<Organization[]>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<OrganizationsRequestBuilderGetQueryParameters> | undefined) : Promise<Organization[] | undefined>;
    /**
     * Create an Organization
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<CreateOrganizationResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     post(body: CreateOrganizationRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<CreateOrganizationResponse | undefined>;
    /**
     * List Organizations with paging and sorting options
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<OrganizationsRequestBuilderGetQueryParameters> | undefined) : RequestInformation;
    /**
     * Create an Organization
     * @param body The request body
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toPostRequestInformation(body: CreateOrganizationRequest, requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * List Organizations with paging and sorting options
 */
export interface OrganizationsRequestBuilderGetQueryParameters {
    /**
     * Gets or sets the page number for the request. Default value is 1.
     */
    pageNumber?: number;
    /**
     * Gets or sets the page size for the request. Default value is 25.
     */
    pageSize?: number;
    /**
     * Gets or sets the sort direction. Can be "ASC" or "DESC". Default value is "asc".
     */
    sortDirection?: string;
    /**
     * Gets or sets the field to sort on. Default value is "Name".
     */
    sortOn?: string;
}
/**
 * Uri template for the request builder.
 */
export const OrganizationsRequestBuilderUriTemplate = "{+baseurl}/api/organizations?pageNumber={pageNumber}&pageSize={pageSize}&sortDirection={sortDirection}&sortOn={sortOn}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const OrganizationsRequestBuilderNavigationMetadata: Record<Exclude<keyof OrganizationsRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    priceTiers: {
        requestsMetadata: PriceTiersRequestBuilderRequestsMetadata,
    },
    salesreps: {
        requestsMetadata: SalesrepsRequestBuilderRequestsMetadata,
        navigationMetadata: SalesrepsRequestBuilderNavigationMetadata,
    },
    subscriptions: {
        navigationMetadata: SubscriptionsRequestBuilderNavigationMetadata,
    },
};
/**
 * Metadata for all the requests in the request builder.
 */
export const OrganizationsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: OrganizationsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "sendCollection",
        responseBodyFactory:  createOrganizationFromDiscriminatorValue,
    },
    post: {
        uriTemplate: OrganizationsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createCreateOrganizationResponseFromDiscriminatorValue,
        requestBodyContentType: "application/json",
        requestBodySerializer: serializeCreateOrganizationRequest,
        requestInformationContentSetMethod: "setContentFromParsable",
    },
};
/* tslint:enable */
/* eslint-enable */
