<script setup>
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const route = useRoute();
const breadcrumbRoutes = ref([]);

const setBreadcrumbRoutes = () => {
    const paths = route.fullPath.split('/').filter((item) => item !== '' && isNaN(Number(item)));

    if (paths.length > 0) {
        // Make the first item (root) clickable
        breadcrumbRoutes.value = paths.map((item, index) => ({
            name: item.charAt(0).toUpperCase() + item.slice(1),
            path: index === 0 ? '/' + paths.slice(0, 1).join('/') : null // Only root is clickable
        }));
    } else {
        breadcrumbRoutes.value = [];
    }
};

watch(route, () => {
    setBreadcrumbRoutes();
}, { immediate: true });
</script>

<template>
    <nav class="layout-breadcrumb">
        <ol class="flex items-center space-x-2">
            <template v-for="(breadcrumbRoute, i) in breadcrumbRoutes" :key="i">
                <li v-if="i === 0 && breadcrumbRoute.path">
                    <!-- Only the first breadcrumb is a clickable link -->
                    <router-link :to="breadcrumbRoute.path" class="hyperlink">
                        {{ breadcrumbRoute.name }}
                    </router-link>
                </li>
                <li v-else>
                    <span>{{ breadcrumbRoute.name }}</span>
                </li>
                <li v-if="i !== breadcrumbRoutes.length - 1" class="layout-breadcrumb-chevron">/</li>
            </template>
        </ol>
    </nav>
</template>


<style scoped>
</style>