/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { ItemsRequestBuilderNavigationMetadata, ItemsRequestBuilderRequestsMetadata, type ItemsRequestBuilder } from './items/';
// @ts-ignore
import { type BaseRequestBuilder, type KeysToExcludeForNavigationMetadata, type NavigationMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/carts/{customerNumber-id}/{shoppingCartId}
 */
export interface WithShoppingCartItemRequestBuilder extends BaseRequestBuilder<WithShoppingCartItemRequestBuilder> {
    /**
     * The items property
     */
    get items(): ItemsRequestBuilder;
}
/**
 * Uri template for the request builder.
 */
export const WithShoppingCartItemRequestBuilderUriTemplate = "{+baseurl}/api/carts/{customerNumber%2Did}/{shoppingCartId}";
/**
 * Metadata for all the navigation properties in the request builder.
 */
export const WithShoppingCartItemRequestBuilderNavigationMetadata: Record<Exclude<keyof WithShoppingCartItemRequestBuilder, KeysToExcludeForNavigationMetadata>, NavigationMetadata> = {
    items: {
        requestsMetadata: ItemsRequestBuilderRequestsMetadata,
        navigationMetadata: ItemsRequestBuilderNavigationMetadata,
    },
};
/* tslint:enable */
/* eslint-enable */
