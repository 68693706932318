/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createErrorResponseFromDiscriminatorValue, createInternalErrorResponseFromDiscriminatorValue, createListUserOrganizationsResponseFromDiscriminatorValue, type ErrorResponse, type InternalErrorResponse, type ListUserOrganizationsResponse } from '../../../models/';
// @ts-ignore
import { type BaseRequestBuilder, type Parsable, type ParsableFactory, type RequestConfiguration, type RequestInformation, type RequestsMetadata } from '@microsoft/kiota-abstractions';

/**
 * Builds and executes requests for operations under /api/users/organizations
 */
export interface OrganizationsRequestBuilder extends BaseRequestBuilder<OrganizationsRequestBuilder> {
    /**
     * List Organizations for a User
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {Promise<ListUserOrganizationsResponse>}
     * @throws {ErrorResponse} error when the service returns a 400 status code
     * @throws {InternalErrorResponse} error when the service returns a 500 status code
     */
     get(requestConfiguration?: RequestConfiguration<object> | undefined) : Promise<ListUserOrganizationsResponse | undefined>;
    /**
     * List Organizations for a User
     * @param requestConfiguration Configuration for the request such as headers, query parameters, and middleware options.
     * @returns {RequestInformation}
     */
     toGetRequestInformation(requestConfiguration?: RequestConfiguration<object> | undefined) : RequestInformation;
}
/**
 * Uri template for the request builder.
 */
export const OrganizationsRequestBuilderUriTemplate = "{+baseurl}/api/users/organizations";
/**
 * Metadata for all the requests in the request builder.
 */
export const OrganizationsRequestBuilderRequestsMetadata: RequestsMetadata = {
    get: {
        uriTemplate: OrganizationsRequestBuilderUriTemplate,
        responseBodyContentType: "application/json",
        errorMappings: {
            400: createErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
            500: createInternalErrorResponseFromDiscriminatorValue as ParsableFactory<Parsable>,
        },
        adapterMethodName: "send",
        responseBodyFactory:  createListUserOrganizationsResponseFromDiscriminatorValue,
    },
};
/* tslint:enable */
/* eslint-enable */
