
const currentOrgKey = 'currentOrg';

// Save data to localStorage
export function saveCurrentOrg(data: string): void {
    localStorage.setItem(currentOrgKey, JSON.stringify(data));
  }
  
  // Get data from localStorage
  export function getCurrentOrg(): string | null {
    const data = localStorage.getItem(currentOrgKey);
    return data ? JSON.parse(data) : null;
  }
   
  // Clear data from localStorage
  export function clearCurrentOrg(): void {
    localStorage.removeItem(currentOrgKey);
  }