/* tslint:disable */
/* eslint-disable */
// Generated by Microsoft Kiota
// @ts-ignore
import { createUntypedNodeFromDiscriminatorValue, type AdditionalDataHolder, type ApiError, type Parsable, type ParseNode, type SerializationWriter, UntypedNode } from '@microsoft/kiota-abstractions';

export interface AccountSetup extends Parsable {
    /**
     * The isComplete property
     */
    isComplete?: boolean;
    /**
     * The nextStep property
     */
    nextStep?: Step;
    /**
     * The percentageComplete property
     */
    percentageComplete?: number;
    /**
     * The steps property
     */
    steps?: Step[];
}
export interface ActivateSalesRepRequest extends Parsable {
    /**
     * OrganizationSalesRepId 
     */
    organizationSalesRepId?: string;
}
export interface AddItemRequest extends Parsable {
    /**
     * The quantity property
     */
    quantity?: number;
    /**
     * The sku property
     */
    sku?: string;
}
export interface Address extends Parsable {
    /**
     * The address1 property
     */
    address1?: string;
    /**
     * The address2 property
     */
    address2?: string;
    /**
     * The addressType property
     */
    addressType?: AddressType;
    /**
     * The city property
     */
    city?: string;
    /**
     * The code property
     */
    code?: string;
    /**
     * The country property
     */
    country?: string;
    /**
     * The default property
     */
    defaultEscaped?: boolean;
    /**
     * The externalId property
     */
    externalId?: string;
    /**
     * The latitude property
     */
    latitude?: number;
    /**
     * The longitude property
     */
    longitude?: number;
    /**
     * The stateProvince property
     */
    stateProvince?: string;
    /**
     * The zipPostalCode property
     */
    zipPostalCode?: string;
}
export type AddressType = (typeof AddressTypeObject)[keyof typeof AddressTypeObject];
export interface BaseEntity extends Parsable {
    /**
     * The active property
     */
    active?: boolean;
    /**
     * The createdAt property
     */
    createdAt?: Date;
    /**
     * The updatedAt property
     */
    updatedAt?: Date;
}
export interface Card extends Parsable {
    /**
     * The card_type property
     */
    card_type?: string;
    /**
     * The created_at property
     */
    created_at?: number;
    /**
     * The customer_id property
     */
    customer_id?: string;
    /**
     * The expiry_month property
     */
    expiry_month?: number;
    /**
     * The expiry_year property
     */
    expiry_year?: number;
    /**
     * The funding_type property
     */
    funding_type?: string;
    /**
     * The gateway property
     */
    gateway?: string;
    /**
     * The gateway_account_id property
     */
    gateway_account_id?: string;
    /**
     * The iin property
     */
    iin?: number;
    /**
     * The ip_address property
     */
    ip_address?: string;
    /**
     * The last4 property
     */
    last4?: number;
    /**
     * The masked_number property
     */
    masked_number?: string;
    /**
     * The object property
     */
    object?: string;
    /**
     * The payment_source_id property
     */
    payment_source_id?: string;
    /**
     * The resource_version property
     */
    resource_version?: number;
    /**
     * The status property
     */
    status?: string;
    /**
     * The updated_at property
     */
    updated_at?: number;
}
export interface CartItem extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The imageName property
     */
    imageName?: string;
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number;
    /**
     * The price property
     */
    price?: number;
    /**
     * The priceFormatted property
     */
    priceFormatted?: string;
    /**
     * The productName property
     */
    productName?: string;
    /**
     * The quantity property
     */
    quantity?: number;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number;
    /**
     * The sku property
     */
    sku?: string;
    /**
     * The subTotal property
     */
    subTotal?: number;
    /**
     * The subTotalFormatted property
     */
    subTotalFormatted?: string;
}
export interface CheckoutPageResponse extends Parsable {
    /**
     * The createdAt property
     */
    createdAt?: number;
    /**
     * The embed property
     */
    embed?: boolean;
    /**
     * The expiresAt property
     */
    expiresAt?: number;
    /**
     * The id property
     */
    id?: string;
    /**
     * The state property
     */
    state?: string;
    /**
     * The type property
     */
    type?: string;
    /**
     * The url property
     */
    url?: string;
}
export interface CompleteCheckoutRequest extends Parsable {
    /**
     * The channel property
     */
    channel?: string;
    /**
     * The customerNumber property
     */
    customerNumber?: string;
    /**
     * The userType property
     */
    userType?: UserTypes;
}
export interface CompleteCheckoutResponse extends Parsable {
    /**
     * The confirmationId property
     */
    confirmationId?: string;
    /**
     * The orderNumber property
     */
    orderNumber?: string;
}
export interface Contact extends Parsable {
    /**
     * The contactType property
     */
    contactType?: ContactType;
    /**
     * The default property
     */
    defaultEscaped?: boolean;
    /**
     * The emailAddress property
     */
    emailAddress?: string;
    /**
     * The externalId property
     */
    externalId?: string;
    /**
     * The fax property
     */
    fax?: string;
    /**
     * The firstName property
     */
    firstName?: string;
    /**
     * The fullName property
     */
    fullName?: string;
    /**
     * The lastName property
     */
    lastName?: string;
    /**
     * The phone property
     */
    phone?: string;
    /**
     * The title property
     */
    title?: string;
}
export type ContactType = (typeof ContactTypeObject)[keyof typeof ContactTypeObject];
export interface Content extends Parsable {
    /**
     * The card property
     */
    card?: Card;
    /**
     * The customer property
     */
    customer?: Customer;
    /**
     * The invoice property
     */
    invoice?: Invoice;
    /**
     * The subscription property
     */
    subscription?: Subscription2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {AccountSetup}
 */
export function createAccountSetupFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAccountSetup;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ActivateSalesRepRequest}
 */
export function createActivateSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoActivateSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {AddItemRequest}
 */
export function createAddItemRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAddItemRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Address}
 */
export function createAddressFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoAddress;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {BaseEntity}
 */
export function createBaseEntityFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoBaseEntity;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Card}
 */
export function createCardFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCard;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CartItem}
 */
export function createCartItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCartItem;
}
/**
 * Represents a request to create a new shopping cart.
 */
export interface CreateCartRequest extends Parsable {
    /**
     * Gets or sets the quantity of the product to be added to the cart.
     */
    quantity?: number;
    /**
     * Gets or sets the SKU of the product to be added to the cart.
     */
    sku?: string;
    /**
     * Gets or sets the user ID. This property is populated from the "UserId" claim in the user's token.
     */
    userId?: string;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CheckoutPageResponse}
 */
export function createCheckoutPageResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCheckoutPageResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CompleteCheckoutRequest}
 */
export function createCompleteCheckoutRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCompleteCheckoutRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CompleteCheckoutResponse}
 */
export function createCompleteCheckoutResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCompleteCheckoutResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Contact}
 */
export function createContactFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoContact;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Content}
 */
export function createContentFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoContent;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateCartRequest}
 */
export function createCreateCartRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateCartRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateCustomerRequest}
 */
export function createCreateCustomerRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateCustomerRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateManyCustomersRequest}
 */
export function createCreateManyCustomersRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateManyCustomersRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateManyProductsRequest}
 */
export function createCreateManyProductsRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateManyProductsRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrganizationRequest}
 */
export function createCreateOrganizationRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrganizationRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrganizationResponse}
 */
export function createCreateOrganizationResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrganizationResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping}
 */
export function createCreateOrUpdateDataMappingRequest_HeaderMappingField_MappingFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrUpdateDataMappingRequest_HeaderMappingField}
 */
export function createCreateOrUpdateDataMappingRequest_HeaderMappingFieldFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateOrUpdateDataMappingRequest}
 */
export function createCreateOrUpdateDataMappingRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateOrUpdateDataMappingRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreatePriceTierRequest}
 */
export function createCreatePriceTierRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreatePriceTierRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateProductRequest}
 */
export function createCreateProductRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateProductRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateSalesRepRequest}
 */
export function createCreateSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateUserRequest}
 */
export function createCreateUserRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateUserRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {CreateUserResponse}
 */
export function createCreateUserResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCreateUserResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Customer2}
 */
export function createCustomer2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCustomer2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Customer}
 */
export function createCustomerFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoCustomer;
}
export interface CreateCustomerRequest extends Parsable {
    /**
     * The active property
     */
    active?: boolean;
    /**
     * The addresses property
     */
    addresses?: Address[];
    /**
     * The companyEmail property
     */
    companyEmail?: string;
    /**
     * The companyFax property
     */
    companyFax?: string;
    /**
     * The companyName property
     */
    companyName?: string;
    /**
     * The companyPhone property
     */
    companyPhone?: string;
    /**
     * The contacts property
     */
    contacts?: Contact[];
    /**
     * The customerNumber property
     */
    customerNumber?: string;
    /**
     * The pricing property
     */
    pricing?: string;
    /**
     * The repNumber property
     */
    repNumber?: string;
    /**
     * The terms property
     */
    terms?: string;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DataMapping}
 */
export function createDataMappingFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDataMapping;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeactivateSalesRepRequest}
 */
export function createDeactivateSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeactivateSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeletePriceTierRequest}
 */
export function createDeletePriceTierRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeletePriceTierRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {DeleteSalesRepRequest}
 */
export function createDeleteSalesRepRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoDeleteSalesRepRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {EntitlementsResponse}
 */
export function createEntitlementsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoEntitlementsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ErrorResponse_errors}
 */
export function createErrorResponse_errorsFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoErrorResponse_errors;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ErrorResponse}
 */
export function createErrorResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoErrorResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ExistsResponse}
 */
export function createExistsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoExistsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {GetOrganizationResponse}
 */
export function createGetOrganizationResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoGetOrganizationResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {HeaderColumnMap}
 */
export function createHeaderColumnMapFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoHeaderColumnMap;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {HeaderMapping}
 */
export function createHeaderMappingFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoHeaderMapping;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ImageAssignment}
 */
export function createImageAssignmentFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoImageAssignment;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {IngAddress}
 */
export function createIngAddressFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoIngAddress;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {InternalErrorResponse}
 */
export function createInternalErrorResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoInternalErrorResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Invoice}
 */
export function createInvoiceFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoInvoice;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {LineItem}
 */
export function createLineItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoLineItem;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {LinkedPayment}
 */
export function createLinkedPaymentFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoLinkedPayment;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListOfEntitlements}
 */
export function createListOfEntitlementsFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListOfEntitlements;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListOrganizationSalesRepsResponse}
 */
export function createListOrganizationSalesRepsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListOrganizationSalesRepsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListSalesRepOrganizationsResponse}
 */
export function createListSalesRepOrganizationsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListSalesRepOrganizationsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ListUserOrganizationsResponse}
 */
export function createListUserOrganizationsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoListUserOrganizationsResponse;
}
export interface CreateManyCustomersRequest extends Parsable {
    /**
     * The customers property
     */
    customers?: CreateCustomerRequest[];
}
export interface CreateManyProductsRequest extends Parsable {
    /**
     * The products property
     */
    products?: CreateProductRequest[];
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {NotFound}
 */
export function createNotFoundFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoNotFound;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Ok}
 */
export function createOkFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOk;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Organization2}
 */
export function createOrganization2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrganization2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Organization}
 */
export function createOrganizationFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrganization;
}
export interface CreateOrganizationRequest extends Parsable {
    /**
     * The organization property
     */
    organization?: Organization2;
    /**
     * The user property
     */
    user?: User;
}
export interface CreateOrganizationResponse extends Parsable {
    /**
     * The identityOrganizationId property
     */
    identityOrganizationId?: string;
    /**
     * The name property
     */
    name?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The slug property
     */
    slug?: string;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {OrganizationSalesRep}
 */
export function createOrganizationSalesRepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoOrganizationSalesRep;
}
export interface CreateOrUpdateDataMappingRequest extends Parsable {
    /**
     * The headerMappingFields property
     */
    headerMappingFields?: CreateOrUpdateDataMappingRequest_HeaderMappingField[];
    /**
     * The id property
     */
    id?: string;
    /**
     * The name property
     */
    name?: string;
}
export interface CreateOrUpdateDataMappingRequest_HeaderMappingField extends Parsable {
    /**
     * The header property
     */
    header?: string;
    /**
     * The mapping property
     */
    mapping?: CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping;
}
export interface CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping extends Parsable {
    /**
     * The mappedHeaderName property
     */
    mappedHeaderName?: string;
    /**
     * The uploadedHeaderName property
     */
    uploadedHeaderName?: string;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PagedResponseOfCustomer}
 */
export function createPagedResponseOfCustomerFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPagedResponseOfCustomer;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PagedResponseOfProduct}
 */
export function createPagedResponseOfProductFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPagedResponseOfProduct;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PaymentMethod}
 */
export function createPaymentMethodFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPaymentMethod;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PortalSessionLinkResponse}
 */
export function createPortalSessionLinkResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPortalSessionLinkResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {PriceTier}
 */
export function createPriceTierFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoPriceTier;
}
export interface CreatePriceTierRequest extends Parsable {
    /**
     * The currencyCode property
     */
    currencyCode?: string;
    /**
     * The name property
     */
    name?: string;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Product_dynamicProperties}
 */
export function createProduct_dynamicPropertiesFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProduct_dynamicProperties;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Product}
 */
export function createProductFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProduct;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ProductPrice}
 */
export function createProductPriceFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoProductPrice;
}
export interface CreateProductRequest extends Parsable {
    /**
     * The active property
     */
    active?: boolean;
    /**
     * The category property
     */
    category?: string;
    /**
     * The description property
     */
    description?: string;
    /**
     * The dimensions property
     */
    dimensions?: string;
    /**
     * The dimensionsUnit property
     */
    dimensionsUnit?: string;
    /**
     * The imageName property
     */
    imageName?: string;
    /**
     * The images property
     */
    images?: string[];
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number;
    /**
     * The name property
     */
    name?: string;
    /**
     * The price property
     */
    price?: number;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number;
    /**
     * The quantityOnHand property
     */
    quantityOnHand?: number;
    /**
     * The sku property
     */
    sku?: string;
    /**
     * The upc property
     */
    upc?: string;
    /**
     * The weight property
     */
    weight?: number;
    /**
     * The weightUnit property
     */
    weightUnit?: string;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ReadmeAuthResponse}
 */
export function createReadmeAuthResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoReadmeAuthResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Rep}
 */
export function createRepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoRep;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SalesRep}
 */
export function createSalesRepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSalesRep;
}
/**
 * Represents a request to create a Sales Representative.
 */
export interface CreateSalesRepRequest extends Parsable {
    /**
     * Gets or sets the email of the sales representative.
     */
    email?: string;
    /**
     * Gets or sets the first name of the sales representative.
     */
    firstName?: string;
    /**
     * Gets or sets the last name of the sales representative.
     */
    lastName?: string;
    /**
     * Gets or sets the phone number of the sales representative.
     */
    phoneNumber?: string;
    /**
     * Gets or sets the representative number of the sales representative.
     */
    repNumber?: string;
    /**
     * Gets or sets the roles of the sales representative.
     */
    roles?: string[];
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {ShoppingCart}
 */
export function createShoppingCartFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoShoppingCart;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Step}
 */
export function createStepFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoStep;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Subscription2}
 */
export function createSubscription2FromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscription2;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SubscriptionCreatedRequest}
 */
export function createSubscriptionCreatedRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscriptionCreatedRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SubscriptionEntitlement}
 */
export function createSubscriptionEntitlementFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscriptionEntitlement;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {Subscription}
 */
export function createSubscriptionFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscription;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {SubscriptionItem}
 */
export function createSubscriptionItemFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoSubscriptionItem;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UpdateItemRequest}
 */
export function createUpdateItemRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUpdateItemRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UpdatePriceTierRequest}
 */
export function createUpdatePriceTierRequestFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUpdatePriceTierRequest;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {UserExistsResponse}
 */
export function createUserExistsResponseFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUserExistsResponse;
}
/**
 * Creates a new instance of the appropriate class based on discriminator value
 * @param parseNode The parse node to use to read the discriminator value and create the object
 * @returns {User}
 */
export function createUserFromDiscriminatorValue(parseNode: ParseNode | undefined) : ((instance?: Parsable) => Record<string, (node: ParseNode) => void>) {
    return deserializeIntoUser;
}
export interface CreateUserRequest extends Parsable {
    /**
     * Gets or sets the email of the sales representative.
     */
    email?: string;
    /**
     * Gets or sets the first name of the sales representative.
     */
    firstName?: string;
    /**
     * Gets or sets the last name of the sales representative.
     */
    lastName?: string;
    /**
     * Gets or sets the password of the sales representative, this will be ignored if they already have an existing identity account.
     */
    password?: string;
    /**
     * Gets or sets the phone number of the sales representative.
     */
    phoneNumber?: string;
}
export interface CreateUserResponse extends Parsable {
    /**
     * The email property
     */
    email?: string;
    /**
     * The firstName property
     */
    firstName?: string;
    /**
     * The lastName property
     */
    lastName?: string;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string;
}
export type CurrencyCode = (typeof CurrencyCodeObject)[keyof typeof CurrencyCodeObject];
export interface Customer extends Parsable {
    /**
     * The allow_direct_debit property
     */
    allow_direct_debit?: boolean;
    /**
     * The auto_collection property
     */
    auto_collection?: string;
    /**
     * The billing_address property
     */
    billing_address?: IngAddress;
    /**
     * The card_status property
     */
    card_status?: string;
    /**
     * The channel property
     */
    channel?: string;
    /**
     * The created_at property
     */
    created_at?: number;
    /**
     * The created_from_ip property
     */
    created_from_ip?: string;
    /**
     * The deleted property
     */
    deleted?: boolean;
    /**
     * The email property
     */
    email?: string;
    /**
     * The excess_payments property
     */
    excess_payments?: number;
    /**
     * The first_name property
     */
    first_name?: string;
    /**
     * The id property
     */
    id?: string;
    /**
     * The last_name property
     */
    last_name?: string;
    /**
     * The mrr property
     */
    mrr?: number;
    /**
     * The net_term_days property
     */
    net_term_days?: number;
    /**
     * The object property
     */
    object?: string;
    /**
     * The payment_method property
     */
    payment_method?: PaymentMethod;
    /**
     * The pii_cleared property
     */
    pii_cleared?: string;
    /**
     * The preferred_currency_code property
     */
    preferred_currency_code?: string;
    /**
     * The primary_payment_source_id property
     */
    primary_payment_source_id?: string;
    /**
     * The promotional_credits property
     */
    promotional_credits?: number;
    /**
     * The refundable_credits property
     */
    refundable_credits?: number;
    /**
     * The resource_version property
     */
    resource_version?: number;
    /**
     * The taxability property
     */
    taxability?: string;
    /**
     * The unbilled_charges property
     */
    unbilled_charges?: number;
    /**
     * The updated_at property
     */
    updated_at?: number;
}
export interface Customer2 extends BaseEntity, Parsable {
    /**
     * The addresses property
     */
    addresses?: Address[];
    /**
     * The companyEmail property
     */
    companyEmail?: string;
    /**
     * The companyFax property
     */
    companyFax?: string;
    /**
     * The companyName property
     */
    companyName?: string;
    /**
     * The companyPhone property
     */
    companyPhone?: string;
    /**
     * The contacts property
     */
    contacts?: Contact[];
    /**
     * The customerId property
     */
    customerId?: string;
    /**
     * The customerNumber property
     */
    customerNumber?: string;
    /**
     * The defaultBillingAddress property
     */
    defaultBillingAddress?: Address;
    /**
     * The defaultShippingAddress property
     */
    defaultShippingAddress?: Address;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The pricing property
     */
    pricing?: string;
    /**
     * The repNumber property
     */
    repNumber?: string;
    /**
     * The terms property
     */
    terms?: string;
}
export interface DataMapping extends Parsable {
    /**
     * The description property
     */
    description?: string;
    /**
     * The headerMappingFields property
     */
    headerMappingFields?: HeaderColumnMap[];
    /**
     * The id property
     */
    id?: string;
    /**
     * The name property
     */
    name?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
}
export interface DeactivateSalesRepRequest extends Parsable {
    /**
     * OrganizationSalesRepId 
     */
    organizationSalesRepId?: string;
}
export interface DeletePriceTierRequest extends Parsable {
    /**
     * The name property
     */
    name?: string;
}
export interface DeleteSalesRepRequest extends Parsable {
    /**
     * OrganizationSalesRepId 
     */
    organizationSalesRepId?: string;
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoAccountSetup(accountSetup: Partial<AccountSetup> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "isComplete": n => { accountSetup.isComplete = n.getBooleanValue(); },
        "nextStep": n => { accountSetup.nextStep = n.getObjectValue<Step>(createStepFromDiscriminatorValue); },
        "percentageComplete": n => { accountSetup.percentageComplete = n.getNumberValue(); },
        "steps": n => { accountSetup.steps = n.getCollectionOfObjectValues<Step>(createStepFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoActivateSalesRepRequest(activateSalesRepRequest: Partial<ActivateSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationSalesRepId": n => { activateSalesRepRequest.organizationSalesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoAddItemRequest(addItemRequest: Partial<AddItemRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "quantity": n => { addItemRequest.quantity = n.getNumberValue(); },
        "sku": n => { addItemRequest.sku = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoAddress(address: Partial<Address> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "address1": n => { address.address1 = n.getStringValue(); },
        "address2": n => { address.address2 = n.getStringValue(); },
        "addressType": n => { address.addressType = n.getEnumValue<AddressType>(AddressTypeObject); },
        "city": n => { address.city = n.getStringValue(); },
        "code": n => { address.code = n.getStringValue(); },
        "country": n => { address.country = n.getStringValue(); },
        "default": n => { address.defaultEscaped = n.getBooleanValue(); },
        "externalId": n => { address.externalId = n.getStringValue(); },
        "latitude": n => { address.latitude = n.getNumberValue(); },
        "longitude": n => { address.longitude = n.getNumberValue(); },
        "stateProvince": n => { address.stateProvince = n.getStringValue(); },
        "zipPostalCode": n => { address.zipPostalCode = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoBaseEntity(baseEntity: Partial<BaseEntity> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { baseEntity.active = n.getBooleanValue(); },
        "createdAt": n => { baseEntity.createdAt = n.getDateValue(); },
        "updatedAt": n => { baseEntity.updatedAt = n.getDateValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCard(card: Partial<Card> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "card_type": n => { card.card_type = n.getStringValue(); },
        "created_at": n => { card.created_at = n.getNumberValue(); },
        "customer_id": n => { card.customer_id = n.getStringValue(); },
        "expiry_month": n => { card.expiry_month = n.getNumberValue(); },
        "expiry_year": n => { card.expiry_year = n.getNumberValue(); },
        "funding_type": n => { card.funding_type = n.getStringValue(); },
        "gateway": n => { card.gateway = n.getStringValue(); },
        "gateway_account_id": n => { card.gateway_account_id = n.getStringValue(); },
        "iin": n => { card.iin = n.getNumberValue(); },
        "ip_address": n => { card.ip_address = n.getStringValue(); },
        "last4": n => { card.last4 = n.getNumberValue(); },
        "masked_number": n => { card.masked_number = n.getStringValue(); },
        "object": n => { card.object = n.getStringValue(); },
        "payment_source_id": n => { card.payment_source_id = n.getStringValue(); },
        "resource_version": n => { card.resource_version = n.getNumberValue(); },
        "status": n => { card.status = n.getStringValue(); },
        "updated_at": n => { card.updated_at = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCartItem(cartItem: Partial<CartItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(cartItem),
        "imageName": n => { cartItem.imageName = n.getStringValue(); },
        "minimumOrderQuantity": n => { cartItem.minimumOrderQuantity = n.getNumberValue(); },
        "price": n => { cartItem.price = n.getNumberValue(); },
        "priceFormatted": n => { cartItem.priceFormatted = n.getStringValue(); },
        "productName": n => { cartItem.productName = n.getStringValue(); },
        "quantity": n => { cartItem.quantity = n.getNumberValue(); },
        "quantityIncrement": n => { cartItem.quantityIncrement = n.getNumberValue(); },
        "sku": n => { cartItem.sku = n.getStringValue(); },
        "subTotal": n => { cartItem.subTotal = n.getNumberValue(); },
        "subTotalFormatted": n => { cartItem.subTotalFormatted = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCheckoutPageResponse(checkoutPageResponse: Partial<CheckoutPageResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "createdAt": n => { checkoutPageResponse.createdAt = n.getNumberValue(); },
        "embed": n => { checkoutPageResponse.embed = n.getBooleanValue(); },
        "expiresAt": n => { checkoutPageResponse.expiresAt = n.getNumberValue(); },
        "id": n => { checkoutPageResponse.id = n.getStringValue(); },
        "state": n => { checkoutPageResponse.state = n.getStringValue(); },
        "type": n => { checkoutPageResponse.type = n.getStringValue(); },
        "url": n => { checkoutPageResponse.url = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCompleteCheckoutRequest(completeCheckoutRequest: Partial<CompleteCheckoutRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "channel": n => { completeCheckoutRequest.channel = n.getStringValue(); },
        "customerNumber": n => { completeCheckoutRequest.customerNumber = n.getStringValue(); },
        "userType": n => { completeCheckoutRequest.userType = n.getEnumValue<UserTypes>(UserTypesObject); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCompleteCheckoutResponse(completeCheckoutResponse: Partial<CompleteCheckoutResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "confirmationId": n => { completeCheckoutResponse.confirmationId = n.getStringValue(); },
        "orderNumber": n => { completeCheckoutResponse.orderNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoContact(contact: Partial<Contact> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "contactType": n => { contact.contactType = n.getEnumValue<ContactType>(ContactTypeObject); },
        "default": n => { contact.defaultEscaped = n.getBooleanValue(); },
        "emailAddress": n => { contact.emailAddress = n.getStringValue(); },
        "externalId": n => { contact.externalId = n.getStringValue(); },
        "fax": n => { contact.fax = n.getStringValue(); },
        "firstName": n => { contact.firstName = n.getStringValue(); },
        "fullName": n => { contact.fullName = n.getStringValue(); },
        "lastName": n => { contact.lastName = n.getStringValue(); },
        "phone": n => { contact.phone = n.getStringValue(); },
        "title": n => { contact.title = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoContent(content: Partial<Content> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "card": n => { content.card = n.getObjectValue<Card>(createCardFromDiscriminatorValue); },
        "customer": n => { content.customer = n.getObjectValue<Customer>(createCustomerFromDiscriminatorValue); },
        "invoice": n => { content.invoice = n.getObjectValue<Invoice>(createInvoiceFromDiscriminatorValue); },
        "subscription": n => { content.subscription = n.getObjectValue<Subscription2>(createSubscription2FromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateCartRequest(createCartRequest: Partial<CreateCartRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "quantity": n => { createCartRequest.quantity = n.getNumberValue(); },
        "sku": n => { createCartRequest.sku = n.getStringValue(); },
        "userId": n => { createCartRequest.userId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateCustomerRequest(createCustomerRequest: Partial<CreateCustomerRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { createCustomerRequest.active = n.getBooleanValue(); },
        "addresses": n => { createCustomerRequest.addresses = n.getCollectionOfObjectValues<Address>(createAddressFromDiscriminatorValue); },
        "companyEmail": n => { createCustomerRequest.companyEmail = n.getStringValue(); },
        "companyFax": n => { createCustomerRequest.companyFax = n.getStringValue(); },
        "companyName": n => { createCustomerRequest.companyName = n.getStringValue(); },
        "companyPhone": n => { createCustomerRequest.companyPhone = n.getStringValue(); },
        "contacts": n => { createCustomerRequest.contacts = n.getCollectionOfObjectValues<Contact>(createContactFromDiscriminatorValue); },
        "customerNumber": n => { createCustomerRequest.customerNumber = n.getStringValue(); },
        "pricing": n => { createCustomerRequest.pricing = n.getStringValue(); },
        "repNumber": n => { createCustomerRequest.repNumber = n.getStringValue(); },
        "terms": n => { createCustomerRequest.terms = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateManyCustomersRequest(createManyCustomersRequest: Partial<CreateManyCustomersRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "customers": n => { createManyCustomersRequest.customers = n.getCollectionOfObjectValues<CreateCustomerRequest>(createCreateCustomerRequestFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateManyProductsRequest(createManyProductsRequest: Partial<CreateManyProductsRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "products": n => { createManyProductsRequest.products = n.getCollectionOfObjectValues<CreateProductRequest>(createCreateProductRequestFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateOrganizationRequest(createOrganizationRequest: Partial<CreateOrganizationRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organization": n => { createOrganizationRequest.organization = n.getObjectValue<Organization2>(createOrganization2FromDiscriminatorValue); },
        "user": n => { createOrganizationRequest.user = n.getObjectValue<User>(createUserFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateOrganizationResponse(createOrganizationResponse: Partial<CreateOrganizationResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "identityOrganizationId": n => { createOrganizationResponse.identityOrganizationId = n.getStringValue(); },
        "name": n => { createOrganizationResponse.name = n.getStringValue(); },
        "organizationId": n => { createOrganizationResponse.organizationId = n.getStringValue(); },
        "slug": n => { createOrganizationResponse.slug = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateOrUpdateDataMappingRequest(createOrUpdateDataMappingRequest: Partial<CreateOrUpdateDataMappingRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "headerMappingFields": n => { createOrUpdateDataMappingRequest.headerMappingFields = n.getCollectionOfObjectValues<CreateOrUpdateDataMappingRequest_HeaderMappingField>(createCreateOrUpdateDataMappingRequest_HeaderMappingFieldFromDiscriminatorValue); },
        "id": n => { createOrUpdateDataMappingRequest.id = n.getStringValue(); },
        "name": n => { createOrUpdateDataMappingRequest.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField(createOrUpdateDataMappingRequest_HeaderMappingField: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "header": n => { createOrUpdateDataMappingRequest_HeaderMappingField.header = n.getStringValue(); },
        "mapping": n => { createOrUpdateDataMappingRequest_HeaderMappingField.mapping = n.getObjectValue<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping>(createCreateOrUpdateDataMappingRequest_HeaderMappingField_MappingFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping(createOrUpdateDataMappingRequest_HeaderMappingField_Mapping: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "mappedHeaderName": n => { createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.mappedHeaderName = n.getStringValue(); },
        "uploadedHeaderName": n => { createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.uploadedHeaderName = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreatePriceTierRequest(createPriceTierRequest: Partial<CreatePriceTierRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currencyCode": n => { createPriceTierRequest.currencyCode = n.getStringValue(); },
        "name": n => { createPriceTierRequest.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateProductRequest(createProductRequest: Partial<CreateProductRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { createProductRequest.active = n.getBooleanValue(); },
        "category": n => { createProductRequest.category = n.getStringValue(); },
        "description": n => { createProductRequest.description = n.getStringValue(); },
        "dimensions": n => { createProductRequest.dimensions = n.getStringValue(); },
        "dimensionsUnit": n => { createProductRequest.dimensionsUnit = n.getStringValue(); },
        "imageName": n => { createProductRequest.imageName = n.getStringValue(); },
        "images": n => { createProductRequest.images = n.getCollectionOfPrimitiveValues<string>(); },
        "minimumOrderQuantity": n => { createProductRequest.minimumOrderQuantity = n.getNumberValue(); },
        "name": n => { createProductRequest.name = n.getStringValue(); },
        "price": n => { createProductRequest.price = n.getNumberValue(); },
        "quantityIncrement": n => { createProductRequest.quantityIncrement = n.getNumberValue(); },
        "quantityOnHand": n => { createProductRequest.quantityOnHand = n.getNumberValue(); },
        "sku": n => { createProductRequest.sku = n.getStringValue(); },
        "upc": n => { createProductRequest.upc = n.getStringValue(); },
        "weight": n => { createProductRequest.weight = n.getNumberValue(); },
        "weightUnit": n => { createProductRequest.weightUnit = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateSalesRepRequest(createSalesRepRequest: Partial<CreateSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { createSalesRepRequest.email = n.getStringValue(); },
        "firstName": n => { createSalesRepRequest.firstName = n.getStringValue(); },
        "lastName": n => { createSalesRepRequest.lastName = n.getStringValue(); },
        "phoneNumber": n => { createSalesRepRequest.phoneNumber = n.getStringValue(); },
        "repNumber": n => { createSalesRepRequest.repNumber = n.getStringValue(); },
        "roles": n => { createSalesRepRequest.roles = n.getCollectionOfPrimitiveValues<string>(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateUserRequest(createUserRequest: Partial<CreateUserRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { createUserRequest.email = n.getStringValue(); },
        "firstName": n => { createUserRequest.firstName = n.getStringValue(); },
        "lastName": n => { createUserRequest.lastName = n.getStringValue(); },
        "password": n => { createUserRequest.password = n.getStringValue(); },
        "phoneNumber": n => { createUserRequest.phoneNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCreateUserResponse(createUserResponse: Partial<CreateUserResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { createUserResponse.email = n.getStringValue(); },
        "firstName": n => { createUserResponse.firstName = n.getStringValue(); },
        "lastName": n => { createUserResponse.lastName = n.getStringValue(); },
        "phoneNumber": n => { createUserResponse.phoneNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCustomer(customer: Partial<Customer> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "allow_direct_debit": n => { customer.allow_direct_debit = n.getBooleanValue(); },
        "auto_collection": n => { customer.auto_collection = n.getStringValue(); },
        "billing_address": n => { customer.billing_address = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "card_status": n => { customer.card_status = n.getStringValue(); },
        "channel": n => { customer.channel = n.getStringValue(); },
        "created_at": n => { customer.created_at = n.getNumberValue(); },
        "created_from_ip": n => { customer.created_from_ip = n.getStringValue(); },
        "deleted": n => { customer.deleted = n.getBooleanValue(); },
        "email": n => { customer.email = n.getStringValue(); },
        "excess_payments": n => { customer.excess_payments = n.getNumberValue(); },
        "first_name": n => { customer.first_name = n.getStringValue(); },
        "id": n => { customer.id = n.getStringValue(); },
        "last_name": n => { customer.last_name = n.getStringValue(); },
        "mrr": n => { customer.mrr = n.getNumberValue(); },
        "net_term_days": n => { customer.net_term_days = n.getNumberValue(); },
        "object": n => { customer.object = n.getStringValue(); },
        "payment_method": n => { customer.payment_method = n.getObjectValue<PaymentMethod>(createPaymentMethodFromDiscriminatorValue); },
        "pii_cleared": n => { customer.pii_cleared = n.getStringValue(); },
        "preferred_currency_code": n => { customer.preferred_currency_code = n.getStringValue(); },
        "primary_payment_source_id": n => { customer.primary_payment_source_id = n.getStringValue(); },
        "promotional_credits": n => { customer.promotional_credits = n.getNumberValue(); },
        "refundable_credits": n => { customer.refundable_credits = n.getNumberValue(); },
        "resource_version": n => { customer.resource_version = n.getNumberValue(); },
        "taxability": n => { customer.taxability = n.getStringValue(); },
        "unbilled_charges": n => { customer.unbilled_charges = n.getNumberValue(); },
        "updated_at": n => { customer.updated_at = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoCustomer2(customer2: Partial<Customer2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(customer2),
        "addresses": n => { customer2.addresses = n.getCollectionOfObjectValues<Address>(createAddressFromDiscriminatorValue); },
        "companyEmail": n => { customer2.companyEmail = n.getStringValue(); },
        "companyFax": n => { customer2.companyFax = n.getStringValue(); },
        "companyName": n => { customer2.companyName = n.getStringValue(); },
        "companyPhone": n => { customer2.companyPhone = n.getStringValue(); },
        "contacts": n => { customer2.contacts = n.getCollectionOfObjectValues<Contact>(createContactFromDiscriminatorValue); },
        "customerId": n => { customer2.customerId = n.getStringValue(); },
        "customerNumber": n => { customer2.customerNumber = n.getStringValue(); },
        "defaultBillingAddress": n => { customer2.defaultBillingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "defaultShippingAddress": n => { customer2.defaultShippingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "organizationId": n => { customer2.organizationId = n.getStringValue(); },
        "pricing": n => { customer2.pricing = n.getStringValue(); },
        "repNumber": n => { customer2.repNumber = n.getStringValue(); },
        "terms": n => { customer2.terms = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoDataMapping(dataMapping: Partial<DataMapping> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "description": n => { dataMapping.description = n.getStringValue(); },
        "headerMappingFields": n => { dataMapping.headerMappingFields = n.getCollectionOfObjectValues<HeaderColumnMap>(createHeaderColumnMapFromDiscriminatorValue); },
        "id": n => { dataMapping.id = n.getStringValue(); },
        "name": n => { dataMapping.name = n.getStringValue(); },
        "organizationId": n => { dataMapping.organizationId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoDeactivateSalesRepRequest(deactivateSalesRepRequest: Partial<DeactivateSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationSalesRepId": n => { deactivateSalesRepRequest.organizationSalesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoDeletePriceTierRequest(deletePriceTierRequest: Partial<DeletePriceTierRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "name": n => { deletePriceTierRequest.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoDeleteSalesRepRequest(deleteSalesRepRequest: Partial<DeleteSalesRepRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizationSalesRepId": n => { deleteSalesRepRequest.organizationSalesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoEntitlementsResponse(entitlementsResponse: Partial<EntitlementsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "entitlements": n => { entitlementsResponse.entitlements = n.getCollectionOfObjectValues<ListOfEntitlements>(createListOfEntitlementsFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoErrorResponse(errorResponse: Partial<ErrorResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "errors": n => { errorResponse.errors = n.getObjectValue<ErrorResponse_errors>(createErrorResponse_errorsFromDiscriminatorValue); },
        "message": n => { errorResponse.messageEscaped = n.getStringValue() ?? "One or more errors occurred!"; },
        "statusCode": n => { errorResponse.statusCode = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoErrorResponse_errors(errorResponse_errors: Partial<ErrorResponse_errors> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoExistsResponse(existsResponse: Partial<ExistsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "exitsInOrganization": n => { existsResponse.exitsInOrganization = n.getBooleanValue(); },
        "identityExists": n => { existsResponse.identityExists = n.getBooleanValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoGetOrganizationResponse(getOrganizationResponse: Partial<GetOrganizationResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "entitlements": n => { getOrganizationResponse.entitlements = n.getCollectionOfObjectValues<ListOfEntitlements>(createListOfEntitlementsFromDiscriminatorValue); },
        "organization": n => { getOrganizationResponse.organization = n.getObjectValue<Organization>(createOrganizationFromDiscriminatorValue); },
        "subscriptions": n => { getOrganizationResponse.subscriptions = n.getCollectionOfObjectValues<Subscription>(createSubscriptionFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoHeaderColumnMap(headerColumnMap: Partial<HeaderColumnMap> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "header": n => { headerColumnMap.header = n.getStringValue(); },
        "mapping": n => { headerColumnMap.mapping = n.getObjectValue<HeaderMapping>(createHeaderMappingFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoHeaderMapping(headerMapping: Partial<HeaderMapping> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "mappedHeaderName": n => { headerMapping.mappedHeaderName = n.getStringValue(); },
        "uploadedHeaderName": n => { headerMapping.uploadedHeaderName = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoImageAssignment(imageAssignment: Partial<ImageAssignment> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "format": n => { imageAssignment.format = n.getEnumValue<ImageFormat>(ImageFormatObject); },
        "imageId": n => { imageAssignment.imageId = n.getStringValue(); },
        "imageName": n => { imageAssignment.imageName = n.getStringValue(); },
        "position": n => { imageAssignment.position = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoIngAddress(ingAddress: Partial<IngAddress> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "city": n => { ingAddress.city = n.getStringValue(); },
        "country": n => { ingAddress.country = n.getStringValue(); },
        "first_name": n => { ingAddress.first_name = n.getStringValue(); },
        "last_name": n => { ingAddress.last_name = n.getStringValue(); },
        "line1": n => { ingAddress.line1 = n.getStringValue(); },
        "object": n => { ingAddress.object = n.getStringValue(); },
        "state": n => { ingAddress.state = n.getStringValue(); },
        "state_code": n => { ingAddress.state_code = n.getStringValue(); },
        "validation_status": n => { ingAddress.validation_status = n.getStringValue(); },
        "zip": n => { ingAddress.zip = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoInternalErrorResponse(internalErrorResponse: Partial<InternalErrorResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "code": n => { internalErrorResponse.code = n.getNumberValue(); },
        "note": n => { internalErrorResponse.note = n.getStringValue() ?? "See application log for stack trace."; },
        "reason": n => { internalErrorResponse.reason = n.getStringValue() ?? "Something unexpected has happened"; },
        "status": n => { internalErrorResponse.status = n.getStringValue() ?? "Internal Server Error!"; },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoInvoice(invoice: Partial<Invoice> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "adjustment_credit_notes": n => { invoice.adjustment_credit_notes = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "amount_adjusted": n => { invoice.amount_adjusted = n.getNumberValue(); },
        "amount_due": n => { invoice.amount_due = n.getNumberValue(); },
        "amount_paid": n => { invoice.amount_paid = n.getNumberValue(); },
        "amount_to_collect": n => { invoice.amount_to_collect = n.getNumberValue(); },
        "applied_credits": n => { invoice.applied_credits = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "base_currency_code": n => { invoice.base_currency_code = n.getStringValue(); },
        "billing_address": n => { invoice.billing_address = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "channel": n => { invoice.channel = n.getStringValue(); },
        "credits_applied": n => { invoice.credits_applied = n.getNumberValue(); },
        "currency_code": n => { invoice.currency_code = n.getStringValue(); },
        "customer_id": n => { invoice.customer_id = n.getStringValue(); },
        "date": n => { invoice.date = n.getNumberValue(); },
        "deleted": n => { invoice.deleted = n.getBooleanValue(); },
        "due_date": n => { invoice.due_date = n.getNumberValue(); },
        "dunning_attempts": n => { invoice.dunning_attempts = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "exchange_rate": n => { invoice.exchange_rate = n.getNumberValue(); },
        "first_invoice": n => { invoice.first_invoice = n.getBooleanValue(); },
        "generated_at": n => { invoice.generated_at = n.getNumberValue(); },
        "has_advance_charges": n => { invoice.has_advance_charges = n.getBooleanValue(); },
        "id": n => { invoice.id = n.getNumberValue(); },
        "issued_credit_notes": n => { invoice.issued_credit_notes = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "is_gifted": n => { invoice.is_gifted = n.getBooleanValue(); },
        "line_items": n => { invoice.line_items = n.getCollectionOfObjectValues<LineItem>(createLineItemFromDiscriminatorValue); },
        "linked_orders": n => { invoice.linked_orders = n.getObjectValue<UntypedNode>(createUntypedNodeFromDiscriminatorValue); },
        "linked_payments": n => { invoice.linked_payments = n.getCollectionOfObjectValues<LinkedPayment>(createLinkedPaymentFromDiscriminatorValue); },
        "net_term_days": n => { invoice.net_term_days = n.getNumberValue(); },
        "new_sales_amount": n => { invoice.new_sales_amount = n.getNumberValue(); },
        "object": n => { invoice.object = n.getStringValue(); },
        "paid_at": n => { invoice.paid_at = n.getNumberValue(); },
        "price_type": n => { invoice.price_type = n.getStringValue(); },
        "recurring": n => { invoice.recurring = n.getBooleanValue(); },
        "resource_version": n => { invoice.resource_version = n.getNumberValue(); },
        "round_off_amount": n => { invoice.round_off_amount = n.getNumberValue(); },
        "shipping_address": n => { invoice.shipping_address = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "status": n => { invoice.status = n.getStringValue(); },
        "subscription_id": n => { invoice.subscription_id = n.getStringValue(); },
        "sub_total": n => { invoice.sub_total = n.getNumberValue(); },
        "tax": n => { invoice.tax = n.getNumberValue(); },
        "term_finalized": n => { invoice.term_finalized = n.getBooleanValue(); },
        "total": n => { invoice.total = n.getNumberValue(); },
        "updated_at": n => { invoice.updated_at = n.getNumberValue(); },
        "write_off_amount": n => { invoice.write_off_amount = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoLineItem(lineItem: Partial<LineItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "amount": n => { lineItem.amount = n.getNumberValue(); },
        "customer_id": n => { lineItem.customer_id = n.getStringValue(); },
        "date_from": n => { lineItem.date_from = n.getNumberValue(); },
        "date_to": n => { lineItem.date_to = n.getNumberValue(); },
        "description": n => { lineItem.description = n.getStringValue(); },
        "discount_amount": n => { lineItem.discount_amount = n.getNumberValue(); },
        "entity_id": n => { lineItem.entity_id = n.getStringValue(); },
        "entity_type": n => { lineItem.entity_type = n.getStringValue(); },
        "id": n => { lineItem.id = n.getStringValue(); },
        "is_taxed": n => { lineItem.is_taxed = n.getBooleanValue(); },
        "item_level_discount_amount": n => { lineItem.item_level_discount_amount = n.getNumberValue(); },
        "object": n => { lineItem.object = n.getStringValue(); },
        "pricing_model": n => { lineItem.pricing_model = n.getStringValue(); },
        "quantity": n => { lineItem.quantity = n.getNumberValue(); },
        "subscription_id": n => { lineItem.subscription_id = n.getStringValue(); },
        "tax_amount": n => { lineItem.tax_amount = n.getNumberValue(); },
        "tax_exempt_reason": n => { lineItem.tax_exempt_reason = n.getStringValue(); },
        "unit_amount": n => { lineItem.unit_amount = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoLinkedPayment(linkedPayment: Partial<LinkedPayment> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "applied_amount": n => { linkedPayment.applied_amount = n.getNumberValue(); },
        "applied_at": n => { linkedPayment.applied_at = n.getNumberValue(); },
        "txn_amount": n => { linkedPayment.txn_amount = n.getNumberValue(); },
        "txn_date": n => { linkedPayment.txn_date = n.getNumberValue(); },
        "txn_id": n => { linkedPayment.txn_id = n.getStringValue(); },
        "txn_status": n => { linkedPayment.txn_status = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoListOfEntitlements(listOfEntitlements: Partial<ListOfEntitlements> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "subscriptionEntitlement": n => { listOfEntitlements.subscriptionEntitlement = n.getObjectValue<SubscriptionEntitlement>(createSubscriptionEntitlementFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoListOrganizationSalesRepsResponse(listOrganizationSalesRepsResponse: Partial<ListOrganizationSalesRepsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "active": n => { listOrganizationSalesRepsResponse.active = n.getBooleanValue(); },
        "email": n => { listOrganizationSalesRepsResponse.email = n.getStringValue(); },
        "firstName": n => { listOrganizationSalesRepsResponse.firstName = n.getStringValue(); },
        "lastName": n => { listOrganizationSalesRepsResponse.lastName = n.getStringValue(); },
        "organizationId": n => { listOrganizationSalesRepsResponse.organizationId = n.getStringValue(); },
        "organizationSalesRepId": n => { listOrganizationSalesRepsResponse.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { listOrganizationSalesRepsResponse.phoneNumber = n.getStringValue(); },
        "repNumber": n => { listOrganizationSalesRepsResponse.repNumber = n.getStringValue(); },
        "salesRepId": n => { listOrganizationSalesRepsResponse.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoListSalesRepOrganizationsResponse(listSalesRepOrganizationsResponse: Partial<ListSalesRepOrganizationsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "salesRepOrganizations": n => { listSalesRepOrganizationsResponse.salesRepOrganizations = n.getCollectionOfObjectValues<OrganizationSalesRep>(createOrganizationSalesRepFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoListUserOrganizationsResponse(listUserOrganizationsResponse: Partial<ListUserOrganizationsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "organizations": n => { listUserOrganizationsResponse.organizations = n.getCollectionOfObjectValues<Organization>(createOrganizationFromDiscriminatorValue); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoNotFound(notFound: Partial<NotFound> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "statusCode": n => { notFound.statusCode = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoOk(ok: Partial<Ok> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "statusCode": n => { ok.statusCode = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoOrganization(organization: Partial<Organization> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(organization),
        "accountSetup": n => { organization.accountSetup = n.getObjectValue<AccountSetup>(createAccountSetupFromDiscriminatorValue); },
        "billingAddress1": n => { organization.billingAddress1 = n.getStringValue(); },
        "billingAddress2": n => { organization.billingAddress2 = n.getStringValue(); },
        "billingCity": n => { organization.billingCity = n.getStringValue(); },
        "billingCountry": n => { organization.billingCountry = n.getStringValue(); },
        "billingPostalCode": n => { organization.billingPostalCode = n.getStringValue(); },
        "billingStateProvince": n => { organization.billingStateProvince = n.getStringValue(); },
        "email": n => { organization.email = n.getStringValue(); },
        "identityOrganizationId": n => { organization.identityOrganizationId = n.getStringValue(); },
        "latitude": n => { organization.latitude = n.getNumberValue(); },
        "logoUrl": n => { organization.logoUrl = n.getStringValue(); },
        "longitude": n => { organization.longitude = n.getNumberValue(); },
        "name": n => { organization.name = n.getStringValue(); },
        "organizationId": n => { organization.organizationId = n.getStringValue(); },
        "phoneNumber": n => { organization.phoneNumber = n.getStringValue(); },
        "priceTiers": n => { organization.priceTiers = n.getCollectionOfObjectValues<PriceTier>(createPriceTierFromDiscriminatorValue); },
        "subscriptions": n => { organization.subscriptions = n.getCollectionOfObjectValues<Subscription>(createSubscriptionFromDiscriminatorValue); },
        "terms": n => { organization.terms = n.getCollectionOfEnumValues<Term>(TermObject); },
        "websiteUrl": n => { organization.websiteUrl = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoOrganization2(organization2: Partial<Organization2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "billingAddress1": n => { organization2.billingAddress1 = n.getStringValue(); },
        "billingAddress2": n => { organization2.billingAddress2 = n.getStringValue(); },
        "billingCity": n => { organization2.billingCity = n.getStringValue(); },
        "billingCountry": n => { organization2.billingCountry = n.getStringValue(); },
        "billingPostalCode": n => { organization2.billingPostalCode = n.getStringValue(); },
        "billingStateProvince": n => { organization2.billingStateProvince = n.getStringValue(); },
        "email": n => { organization2.email = n.getStringValue(); },
        "name": n => { organization2.name = n.getStringValue(); },
        "phoneNumber": n => { organization2.phoneNumber = n.getStringValue(); },
        "website": n => { organization2.website = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoOrganizationSalesRep(organizationSalesRep: Partial<OrganizationSalesRep> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(organizationSalesRep),
        "email": n => { organizationSalesRep.email = n.getStringValue(); },
        "firstName": n => { organizationSalesRep.firstName = n.getStringValue(); },
        "identityOrganizationId": n => { organizationSalesRep.identityOrganizationId = n.getStringValue(); },
        "identityProviderUserId": n => { organizationSalesRep.identityProviderUserId = n.getStringValue(); },
        "lastName": n => { organizationSalesRep.lastName = n.getStringValue(); },
        "organizationId": n => { organizationSalesRep.organizationId = n.getStringValue(); },
        "organizationName": n => { organizationSalesRep.organizationName = n.getStringValue(); },
        "organizationSalesRepId": n => { organizationSalesRep.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { organizationSalesRep.phoneNumber = n.getStringValue(); },
        "repNumber": n => { organizationSalesRep.repNumber = n.getStringValue(); },
        "salesRepId": n => { organizationSalesRep.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoPagedResponseOfCustomer(pagedResponseOfCustomer: Partial<PagedResponseOfCustomer> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currentPage": n => { pagedResponseOfCustomer.currentPage = n.getNumberValue(); },
        "data": n => { pagedResponseOfCustomer.data = n.getCollectionOfObjectValues<Customer2>(createCustomer2FromDiscriminatorValue); },
        "totalPages": n => { pagedResponseOfCustomer.totalPages = n.getNumberValue(); },
        "totalRecords": n => { pagedResponseOfCustomer.totalRecords = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoPagedResponseOfProduct(pagedResponseOfProduct: Partial<PagedResponseOfProduct> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currentPage": n => { pagedResponseOfProduct.currentPage = n.getNumberValue(); },
        "data": n => { pagedResponseOfProduct.data = n.getCollectionOfObjectValues<Product>(createProductFromDiscriminatorValue); },
        "totalPages": n => { pagedResponseOfProduct.totalPages = n.getNumberValue(); },
        "totalRecords": n => { pagedResponseOfProduct.totalRecords = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoPaymentMethod(paymentMethod: Partial<PaymentMethod> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "gateway": n => { paymentMethod.gateway = n.getStringValue(); },
        "gateway_account_id": n => { paymentMethod.gateway_account_id = n.getStringValue(); },
        "object": n => { paymentMethod.object = n.getStringValue(); },
        "reference_id": n => { paymentMethod.reference_id = n.getStringValue(); },
        "status": n => { paymentMethod.status = n.getStringValue(); },
        "type": n => { paymentMethod.type = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoPortalSessionLinkResponse(portalSessionLinkResponse: Partial<PortalSessionLinkResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "sessionUrl": n => { portalSessionLinkResponse.sessionUrl = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoPriceTier(priceTier: Partial<PriceTier> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currencyCode": n => { priceTier.currencyCode = n.getEnumValue<CurrencyCode>(CurrencyCodeObject); },
        "name": n => { priceTier.name = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoProduct(product: Partial<Product> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(product),
        "category": n => { product.category = n.getStringValue(); },
        "currencyCode": n => { product.currencyCode = n.getEnumValue<CurrencyCode>(CurrencyCodeObject); },
        "description": n => { product.description = n.getStringValue(); },
        "dimensions": n => { product.dimensions = n.getStringValue(); },
        "dimensionsUnit": n => { product.dimensionsUnit = n.getStringValue(); },
        "dynamicProperties": n => { product.dynamicProperties = n.getObjectValue<Product_dynamicProperties>(createProduct_dynamicPropertiesFromDiscriminatorValue); },
        "imageName": n => { product.imageName = n.getStringValue(); },
        "images": n => { product.images = n.getCollectionOfObjectValues<ImageAssignment>(createImageAssignmentFromDiscriminatorValue); },
        "minimumOrderQuantity": n => { product.minimumOrderQuantity = n.getNumberValue(); },
        "name": n => { product.name = n.getStringValue(); },
        "organizationId": n => { product.organizationId = n.getStringValue(); },
        "price": n => { product.price = n.getNumberValue(); },
        "prices": n => { product.prices = n.getCollectionOfObjectValues<ProductPrice>(createProductPriceFromDiscriminatorValue); },
        "productId": n => { product.productId = n.getStringValue(); },
        "productType": n => { product.productType = n.getEnumValue<ProductType>(ProductTypeObject); },
        "quantityIncrement": n => { product.quantityIncrement = n.getNumberValue(); },
        "quantityOnHand": n => { product.quantityOnHand = n.getNumberValue(); },
        "sku": n => { product.sku = n.getStringValue(); },
        "upc": n => { product.upc = n.getStringValue(); },
        "weight": n => { product.weight = n.getNumberValue(); },
        "weightUnit": n => { product.weightUnit = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoProduct_dynamicProperties(product_dynamicProperties: Partial<Product_dynamicProperties> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoProductPrice(productPrice: Partial<ProductPrice> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "atQuantity": n => { productPrice.atQuantity = n.getNumberValue(); },
        "price": n => { productPrice.price = n.getNumberValue(); },
        "tier": n => { productPrice.tier = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoReadmeAuthResponse(readmeAuthResponse: Partial<ReadmeAuthResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "readmeAuthUrl": n => { readmeAuthResponse.readmeAuthUrl = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoRep(rep: Partial<Rep> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { rep.email = n.getStringValue(); },
        "firstName": n => { rep.firstName = n.getStringValue(); },
        "fullName": n => { rep.fullName = n.getStringValue(); },
        "lastName": n => { rep.lastName = n.getStringValue(); },
        "organizationSalesRepId": n => { rep.organizationSalesRepId = n.getStringValue(); },
        "phoneNumber": n => { rep.phoneNumber = n.getStringValue(); },
        "repNumber": n => { rep.repNumber = n.getStringValue(); },
        "salesRepId": n => { rep.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoSalesRep(salesRep: Partial<SalesRep> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(salesRep),
        "email": n => { salesRep.email = n.getStringValue(); },
        "firstName": n => { salesRep.firstName = n.getStringValue(); },
        "fullName": n => { salesRep.fullName = n.getStringValue(); },
        "identityProvider": n => { salesRep.identityProvider = n.getStringValue(); },
        "identityProviderUserId": n => { salesRep.identityProviderUserId = n.getStringValue(); },
        "lastName": n => { salesRep.lastName = n.getStringValue(); },
        "phoneNumber": n => { salesRep.phoneNumber = n.getStringValue(); },
        "pictureUrl": n => { salesRep.pictureUrl = n.getStringValue(); },
        "salesRepId": n => { salesRep.salesRepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoShoppingCart(shoppingCart: Partial<ShoppingCart> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        ...deserializeIntoBaseEntity(shoppingCart),
        "billingAddress": n => { shoppingCart.billingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "cartItems": n => { shoppingCart.cartItems = n.getCollectionOfObjectValues<CartItem>(createCartItemFromDiscriminatorValue); },
        "companyEmail": n => { shoppingCart.companyEmail = n.getStringValue(); },
        "companyFax": n => { shoppingCart.companyFax = n.getStringValue(); },
        "companyName": n => { shoppingCart.companyName = n.getStringValue(); },
        "companyPhone": n => { shoppingCart.companyPhone = n.getStringValue(); },
        "currencyCode": n => { shoppingCart.currencyCode = n.getStringValue(); },
        "customerNumber": n => { shoppingCart.customerNumber = n.getStringValue(); },
        "notes": n => { shoppingCart.notes = n.getStringValue(); },
        "orderTotal": n => { shoppingCart.orderTotal = n.getNumberValue(); },
        "orderTotalFormatted": n => { shoppingCart.orderTotalFormatted = n.getStringValue(); },
        "orderType": n => { shoppingCart.orderType = n.getStringValue(); },
        "organizationId": n => { shoppingCart.organizationId = n.getStringValue(); },
        "poNumber": n => { shoppingCart.poNumber = n.getStringValue(); },
        "priceTier": n => { shoppingCart.priceTier = n.getStringValue(); },
        "primarySalesRep": n => { shoppingCart.primarySalesRep = n.getObjectValue<Rep>(createRepFromDiscriminatorValue); },
        "shipping": n => { shoppingCart.shipping = n.getNumberValue(); },
        "shippingAddress": n => { shoppingCart.shippingAddress = n.getObjectValue<Address>(createAddressFromDiscriminatorValue); },
        "shippingFormatted": n => { shoppingCart.shippingFormatted = n.getStringValue(); },
        "shippingMethod": n => { shoppingCart.shippingMethod = n.getStringValue(); },
        "shoppingCartId": n => { shoppingCart.shoppingCartId = n.getStringValue(); },
        "subtotal": n => { shoppingCart.subtotal = n.getNumberValue(); },
        "subtotalFormatted": n => { shoppingCart.subtotalFormatted = n.getStringValue(); },
        "takenBySalesRep": n => { shoppingCart.takenBySalesRep = n.getObjectValue<Rep>(createRepFromDiscriminatorValue); },
        "tax": n => { shoppingCart.tax = n.getNumberValue(); },
        "taxFormatted": n => { shoppingCart.taxFormatted = n.getStringValue(); },
        "terms": n => { shoppingCart.terms = n.getStringValue(); },
        "userId": n => { shoppingCart.userId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoStep(step: Partial<Step> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "isComplete": n => { step.isComplete = n.getBooleanValue(); },
        "name": n => { step.name = n.getStringValue(); },
        "order": n => { step.order = n.getNumberValue(); },
        "stepId": n => { step.stepId = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoSubscription(subscription: Partial<Subscription> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "id": n => { subscription.id = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoSubscription2(subscription2: Partial<Subscription2> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "activated_at": n => { subscription2.activated_at = n.getNumberValue(); },
        "billing_period": n => { subscription2.billing_period = n.getNumberValue(); },
        "billing_period_unit": n => { subscription2.billing_period_unit = n.getStringValue(); },
        "channel": n => { subscription2.channel = n.getStringValue(); },
        "created_at": n => { subscription2.created_at = n.getNumberValue(); },
        "created_from_ip": n => { subscription2.created_from_ip = n.getStringValue(); },
        "currency_code": n => { subscription2.currency_code = n.getStringValue(); },
        "current_term_end": n => { subscription2.current_term_end = n.getNumberValue(); },
        "current_term_start": n => { subscription2.current_term_start = n.getNumberValue(); },
        "customer_id": n => { subscription2.customer_id = n.getStringValue(); },
        "deleted": n => { subscription2.deleted = n.getBooleanValue(); },
        "due_invoices_count": n => { subscription2.due_invoices_count = n.getNumberValue(); },
        "has_scheduled_advance_invoices": n => { subscription2.has_scheduled_advance_invoices = n.getBooleanValue(); },
        "has_scheduled_changes": n => { subscription2.has_scheduled_changes = n.getBooleanValue(); },
        "id": n => { subscription2.id = n.getStringValue(); },
        "mrr": n => { subscription2.mrr = n.getNumberValue(); },
        "next_billing_at": n => { subscription2.next_billing_at = n.getNumberValue(); },
        "object": n => { subscription2.object = n.getStringValue(); },
        "resource_version": n => { subscription2.resource_version = n.getNumberValue(); },
        "shipping_address": n => { subscription2.shipping_address = n.getObjectValue<IngAddress>(createIngAddressFromDiscriminatorValue); },
        "started_at": n => { subscription2.started_at = n.getNumberValue(); },
        "status": n => { subscription2.status = n.getStringValue(); },
        "subscription_items": n => { subscription2.subscription_items = n.getCollectionOfObjectValues<SubscriptionItem>(createSubscriptionItemFromDiscriminatorValue); },
        "updated_at": n => { subscription2.updated_at = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoSubscriptionCreatedRequest(subscriptionCreatedRequest: Partial<SubscriptionCreatedRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "api_version": n => { subscriptionCreatedRequest.api_version = n.getStringValue(); },
        "content": n => { subscriptionCreatedRequest.content = n.getObjectValue<Content>(createContentFromDiscriminatorValue); },
        "event_type": n => { subscriptionCreatedRequest.event_type = n.getStringValue(); },
        "id": n => { subscriptionCreatedRequest.id = n.getStringValue(); },
        "object": n => { subscriptionCreatedRequest.object = n.getStringValue(); },
        "occurred_at": n => { subscriptionCreatedRequest.occurred_at = n.getNumberValue(); },
        "source": n => { subscriptionCreatedRequest.source = n.getStringValue(); },
        "webhook_status": n => { subscriptionCreatedRequest.webhook_status = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoSubscriptionEntitlement(subscriptionEntitlement: Partial<SubscriptionEntitlement> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "featureId": n => { subscriptionEntitlement.featureId = n.getStringValue(); },
        "featureName": n => { subscriptionEntitlement.featureName = n.getStringValue(); },
        "id": n => { subscriptionEntitlement.id = n.getStringValue(); },
        "isOverridden": n => { subscriptionEntitlement.isOverridden = n.getBooleanValue(); },
        "name": n => { subscriptionEntitlement.name = n.getStringValue(); },
        "subscriptionId": n => { subscriptionEntitlement.subscriptionId = n.getStringValue(); },
        "value": n => { subscriptionEntitlement.value = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoSubscriptionItem(subscriptionItem: Partial<SubscriptionItem> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "amount": n => { subscriptionItem.amount = n.getNumberValue(); },
        "free_quantity": n => { subscriptionItem.free_quantity = n.getNumberValue(); },
        "item_price_id": n => { subscriptionItem.item_price_id = n.getStringValue(); },
        "item_type": n => { subscriptionItem.item_type = n.getStringValue(); },
        "object": n => { subscriptionItem.object = n.getStringValue(); },
        "quantity": n => { subscriptionItem.quantity = n.getNumberValue(); },
        "unit_price": n => { subscriptionItem.unit_price = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoUpdateItemRequest(updateItemRequest: Partial<UpdateItemRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "quantity": n => { updateItemRequest.quantity = n.getNumberValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoUpdatePriceTierRequest(updatePriceTierRequest: Partial<UpdatePriceTierRequest> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "currencyCode": n => { updatePriceTierRequest.currencyCode = n.getStringValue(); },
        "newName": n => { updatePriceTierRequest.newName = n.getStringValue(); },
        "oldName": n => { updatePriceTierRequest.oldName = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoUser(user: Partial<User> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "email": n => { user.email = n.getStringValue(); },
        "firstName": n => { user.firstName = n.getStringValue(); },
        "lastName": n => { user.lastName = n.getStringValue(); },
        "password": n => { user.password = n.getStringValue(); },
        "phoneNumber": n => { user.phoneNumber = n.getStringValue(); },
    }
}
/**
 * The deserialization information for the current model
 * @returns {Record<string, (node: ParseNode) => void>}
 */
export function deserializeIntoUserExistsResponse(userExistsResponse: Partial<UserExistsResponse> | undefined = {}) : Record<string, (node: ParseNode) => void> {
    return {
        "userExists": n => { userExistsResponse.userExists = n.getBooleanValue(); },
    }
}
export interface EntitlementsResponse extends Parsable {
    /**
     * The entitlements property
     */
    entitlements?: ListOfEntitlements[];
}
/**
 * the dto used to send an error response to the client
 */
export interface ErrorResponse extends ApiError, Parsable {
    /**
     * the collection of errors for the current context
     */
    errors?: ErrorResponse_errors;
    /**
     * the message for the error response
     */
    messageEscaped?: string;
    /**
     * the http status code sent to the client. default is 400.
     */
    statusCode?: number;
}
/**
 * the collection of errors for the current context
 */
export interface ErrorResponse_errors extends AdditionalDataHolder, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
}
export interface ExistsResponse extends Parsable {
    /**
     * The exitsInOrganization property
     */
    exitsInOrganization?: boolean;
    /**
     * The identityExists property
     */
    identityExists?: boolean;
}
export interface GetOrganizationResponse extends Parsable {
    /**
     * The entitlements property
     */
    entitlements?: ListOfEntitlements[];
    /**
     * The organization property
     */
    organization?: Organization;
    /**
     * The subscriptions property
     */
    subscriptions?: Subscription[];
}
export interface HeaderColumnMap extends Parsable {
    /**
     * The header property
     */
    header?: string;
    /**
     * The mapping property
     */
    mapping?: HeaderMapping;
}
export interface HeaderMapping extends Parsable {
    /**
     * The mappedHeaderName property
     */
    mappedHeaderName?: string;
    /**
     * The uploadedHeaderName property
     */
    uploadedHeaderName?: string;
}
export interface ImageAssignment extends Parsable {
    /**
     * The format property
     */
    format?: ImageFormat;
    /**
     * The imageId property
     */
    imageId?: string;
    /**
     * The imageName property
     */
    imageName?: string;
    /**
     * The position property
     */
    position?: number;
}
export type ImageFormat = (typeof ImageFormatObject)[keyof typeof ImageFormatObject];
export interface IngAddress extends Parsable {
    /**
     * The city property
     */
    city?: string;
    /**
     * The country property
     */
    country?: string;
    /**
     * The first_name property
     */
    first_name?: string;
    /**
     * The last_name property
     */
    last_name?: string;
    /**
     * The line1 property
     */
    line1?: string;
    /**
     * The object property
     */
    object?: string;
    /**
     * The state property
     */
    state?: string;
    /**
     * The state_code property
     */
    state_code?: string;
    /**
     * The validation_status property
     */
    validation_status?: string;
    /**
     * The zip property
     */
    zip?: number;
}
/**
 * the dto used to send an error response to the client when an unhandled exception occurs on the server
 */
export interface InternalErrorResponse extends ApiError, Parsable {
    /**
     * http status code of the error response
     */
    code?: number;
    /**
     * additional information or instructions
     */
    note?: string;
    /**
     * the reason for the error
     */
    reason?: string;
    /**
     * error status
     */
    status?: string;
}
export interface Invoice extends Parsable {
    /**
     * The adjustment_credit_notes property
     */
    adjustment_credit_notes?: UntypedNode;
    /**
     * The amount_adjusted property
     */
    amount_adjusted?: number;
    /**
     * The amount_due property
     */
    amount_due?: number;
    /**
     * The amount_paid property
     */
    amount_paid?: number;
    /**
     * The amount_to_collect property
     */
    amount_to_collect?: number;
    /**
     * The applied_credits property
     */
    applied_credits?: UntypedNode;
    /**
     * The base_currency_code property
     */
    base_currency_code?: string;
    /**
     * The billing_address property
     */
    billing_address?: IngAddress;
    /**
     * The channel property
     */
    channel?: string;
    /**
     * The credits_applied property
     */
    credits_applied?: number;
    /**
     * The currency_code property
     */
    currency_code?: string;
    /**
     * The customer_id property
     */
    customer_id?: string;
    /**
     * The date property
     */
    date?: number;
    /**
     * The deleted property
     */
    deleted?: boolean;
    /**
     * The due_date property
     */
    due_date?: number;
    /**
     * The dunning_attempts property
     */
    dunning_attempts?: UntypedNode;
    /**
     * The exchange_rate property
     */
    exchange_rate?: number;
    /**
     * The first_invoice property
     */
    first_invoice?: boolean;
    /**
     * The generated_at property
     */
    generated_at?: number;
    /**
     * The has_advance_charges property
     */
    has_advance_charges?: boolean;
    /**
     * The id property
     */
    id?: number;
    /**
     * The is_gifted property
     */
    is_gifted?: boolean;
    /**
     * The issued_credit_notes property
     */
    issued_credit_notes?: UntypedNode;
    /**
     * The line_items property
     */
    line_items?: LineItem[];
    /**
     * The linked_orders property
     */
    linked_orders?: UntypedNode;
    /**
     * The linked_payments property
     */
    linked_payments?: LinkedPayment[];
    /**
     * The net_term_days property
     */
    net_term_days?: number;
    /**
     * The new_sales_amount property
     */
    new_sales_amount?: number;
    /**
     * The object property
     */
    object?: string;
    /**
     * The paid_at property
     */
    paid_at?: number;
    /**
     * The price_type property
     */
    price_type?: string;
    /**
     * The recurring property
     */
    recurring?: boolean;
    /**
     * The resource_version property
     */
    resource_version?: number;
    /**
     * The round_off_amount property
     */
    round_off_amount?: number;
    /**
     * The shipping_address property
     */
    shipping_address?: IngAddress;
    /**
     * The status property
     */
    status?: string;
    /**
     * The sub_total property
     */
    sub_total?: number;
    /**
     * The subscription_id property
     */
    subscription_id?: string;
    /**
     * The tax property
     */
    tax?: number;
    /**
     * The term_finalized property
     */
    term_finalized?: boolean;
    /**
     * The total property
     */
    total?: number;
    /**
     * The updated_at property
     */
    updated_at?: number;
    /**
     * The write_off_amount property
     */
    write_off_amount?: number;
}
export interface LineItem extends Parsable {
    /**
     * The amount property
     */
    amount?: number;
    /**
     * The customer_id property
     */
    customer_id?: string;
    /**
     * The date_from property
     */
    date_from?: number;
    /**
     * The date_to property
     */
    date_to?: number;
    /**
     * The description property
     */
    description?: string;
    /**
     * The discount_amount property
     */
    discount_amount?: number;
    /**
     * The entity_id property
     */
    entity_id?: string;
    /**
     * The entity_type property
     */
    entity_type?: string;
    /**
     * The id property
     */
    id?: string;
    /**
     * The is_taxed property
     */
    is_taxed?: boolean;
    /**
     * The item_level_discount_amount property
     */
    item_level_discount_amount?: number;
    /**
     * The object property
     */
    object?: string;
    /**
     * The pricing_model property
     */
    pricing_model?: string;
    /**
     * The quantity property
     */
    quantity?: number;
    /**
     * The subscription_id property
     */
    subscription_id?: string;
    /**
     * The tax_amount property
     */
    tax_amount?: number;
    /**
     * The tax_exempt_reason property
     */
    tax_exempt_reason?: string;
    /**
     * The unit_amount property
     */
    unit_amount?: number;
}
export interface LinkedPayment extends Parsable {
    /**
     * The applied_amount property
     */
    applied_amount?: number;
    /**
     * The applied_at property
     */
    applied_at?: number;
    /**
     * The txn_amount property
     */
    txn_amount?: number;
    /**
     * The txn_date property
     */
    txn_date?: number;
    /**
     * The txn_id property
     */
    txn_id?: string;
    /**
     * The txn_status property
     */
    txn_status?: string;
}
export interface ListOfEntitlements extends Parsable {
    /**
     * The subscriptionEntitlement property
     */
    subscriptionEntitlement?: SubscriptionEntitlement;
}
export interface ListOrganizationSalesRepsResponse extends Parsable {
    /**
     * The active property
     */
    active?: boolean;
    /**
     * The email property
     */
    email?: string;
    /**
     * The firstName property
     */
    firstName?: string;
    /**
     * The lastName property
     */
    lastName?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string;
    /**
     * The repNumber property
     */
    repNumber?: string;
    /**
     * The salesRepId property
     */
    salesRepId?: string;
}
export interface ListSalesRepOrganizationsResponse extends Parsable {
    /**
     * The salesRepOrganizations property
     */
    salesRepOrganizations?: OrganizationSalesRep[];
}
export interface ListUserOrganizationsResponse extends Parsable {
    /**
     * The organizations property
     */
    organizations?: Organization[];
}
export interface NotFound extends ApiError, Parsable {
    /**
     * The statusCode property
     */
    statusCode?: number;
}
export interface Ok extends Parsable {
    /**
     * The statusCode property
     */
    statusCode?: number;
}
export interface Organization extends BaseEntity, Parsable {
    /**
     * The accountSetup property
     */
    accountSetup?: AccountSetup;
    /**
     * The billingAddress1 property
     */
    billingAddress1?: string;
    /**
     * The billingAddress2 property
     */
    billingAddress2?: string;
    /**
     * The billingCity property
     */
    billingCity?: string;
    /**
     * The billingCountry property
     */
    billingCountry?: string;
    /**
     * The billingPostalCode property
     */
    billingPostalCode?: string;
    /**
     * The billingStateProvince property
     */
    billingStateProvince?: string;
    /**
     * The email property
     */
    email?: string;
    /**
     * The identityOrganizationId property
     */
    identityOrganizationId?: string;
    /**
     * The latitude property
     */
    latitude?: number;
    /**
     * The logoUrl property
     */
    logoUrl?: string;
    /**
     * The longitude property
     */
    longitude?: number;
    /**
     * The name property
     */
    name?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string;
    /**
     * The priceTiers property
     */
    priceTiers?: PriceTier[];
    /**
     * The subscriptions property
     */
    subscriptions?: Subscription[];
    /**
     * The terms property
     */
    terms?: Term[];
    /**
     * The websiteUrl property
     */
    websiteUrl?: string;
}
export interface Organization2 extends Parsable {
    /**
     * Gets or sets the billing address of the organization.
     */
    billingAddress1?: string;
    /**
     * Gets or sets the second line of the billing address of the organization.
     */
    billingAddress2?: string;
    /**
     * Gets or sets the billing city of the organization.
     */
    billingCity?: string;
    /**
     * Gets or sets the billing country of the organization.
     */
    billingCountry?: string;
    /**
     * Gets or sets the billing postal code of the organization.
     */
    billingPostalCode?: string;
    /**
     * Gets or sets the billing state/province of the organization.
     */
    billingStateProvince?: string;
    /**
     * Gets or sets the email of the organization.
     */
    email?: string;
    /**
     * Gets or sets the name of the organization.
     */
    name?: string;
    /**
     * Gets or sets the phone number of the organization. 
     */
    phoneNumber?: string;
    /**
     * Gets or sets the website of the organization.
     */
    website?: string;
}
export interface OrganizationSalesRep extends BaseEntity, Parsable {
    /**
     * The email property
     */
    email?: string;
    /**
     * The firstName property
     */
    firstName?: string;
    /**
     * The identityOrganizationId property
     */
    identityOrganizationId?: string;
    /**
     * The identityProviderUserId property
     */
    identityProviderUserId?: string;
    /**
     * The lastName property
     */
    lastName?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The organizationName property
     */
    organizationName?: string;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string;
    /**
     * The repNumber property
     */
    repNumber?: string;
    /**
     * The salesRepId property
     */
    salesRepId?: string;
}
export interface PagedResponseOfCustomer extends Parsable {
    /**
     * The currentPage property
     */
    currentPage?: number;
    /**
     * The data property
     */
    data?: Customer2[];
    /**
     * The totalPages property
     */
    totalPages?: number;
    /**
     * The totalRecords property
     */
    totalRecords?: number;
}
export interface PagedResponseOfProduct extends Parsable {
    /**
     * The currentPage property
     */
    currentPage?: number;
    /**
     * The data property
     */
    data?: Product[];
    /**
     * The totalPages property
     */
    totalPages?: number;
    /**
     * The totalRecords property
     */
    totalRecords?: number;
}
export interface PaymentMethod extends Parsable {
    /**
     * The gateway property
     */
    gateway?: string;
    /**
     * The gateway_account_id property
     */
    gateway_account_id?: string;
    /**
     * The object property
     */
    object?: string;
    /**
     * The reference_id property
     */
    reference_id?: string;
    /**
     * The status property
     */
    status?: string;
    /**
     * The type property
     */
    type?: string;
}
export interface PortalSessionLinkResponse extends Parsable {
    /**
     * The sessionUrl property
     */
    sessionUrl?: string;
}
export interface PriceTier extends Parsable {
    /**
     * The currencyCode property
     */
    currencyCode?: CurrencyCode;
    /**
     * The name property
     */
    name?: string;
}
export interface Product extends BaseEntity, Parsable {
    /**
     * The category property
     */
    category?: string;
    /**
     * The currencyCode property
     */
    currencyCode?: CurrencyCode;
    /**
     * The description property
     */
    description?: string;
    /**
     * The dimensions property
     */
    dimensions?: string;
    /**
     * The dimensionsUnit property
     */
    dimensionsUnit?: string;
    /**
     * The dynamicProperties property
     */
    dynamicProperties?: Product_dynamicProperties;
    /**
     * The imageName property
     */
    imageName?: string;
    /**
     * The images property
     */
    images?: ImageAssignment[];
    /**
     * The minimumOrderQuantity property
     */
    minimumOrderQuantity?: number;
    /**
     * The name property
     */
    name?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The price property
     */
    price?: number;
    /**
     * The prices property
     */
    prices?: ProductPrice[];
    /**
     * The productId property
     */
    productId?: string;
    /**
     * The productType property
     */
    productType?: ProductType;
    /**
     * The quantityIncrement property
     */
    quantityIncrement?: number;
    /**
     * The quantityOnHand property
     */
    quantityOnHand?: number;
    /**
     * The sku property
     */
    sku?: string;
    /**
     * The upc property
     */
    upc?: string;
    /**
     * The weight property
     */
    weight?: number;
    /**
     * The weightUnit property
     */
    weightUnit?: string;
}
export interface Product_dynamicProperties extends AdditionalDataHolder, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
}
export interface ProductPrice extends Parsable {
    /**
     * The atQuantity property
     */
    atQuantity?: number;
    /**
     * The price property
     */
    price?: number;
    /**
     * The tier property
     */
    tier?: string;
}
export type ProductType = (typeof ProductTypeObject)[keyof typeof ProductTypeObject];
export interface ReadmeAuthResponse extends Parsable {
    /**
     * The readmeAuthUrl property
     */
    readmeAuthUrl?: string;
}
export interface Rep extends Parsable {
    /**
     * The email property
     */
    email?: string;
    /**
     * The firstName property
     */
    firstName?: string;
    /**
     * The fullName property
     */
    fullName?: string;
    /**
     * The lastName property
     */
    lastName?: string;
    /**
     * The organizationSalesRepId property
     */
    organizationSalesRepId?: string;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string;
    /**
     * The repNumber property
     */
    repNumber?: string;
    /**
     * The salesRepId property
     */
    salesRepId?: string;
}
export interface SalesRep extends AdditionalDataHolder, BaseEntity, Parsable {
    /**
     * Stores additional data not described in the OpenAPI description found when deserializing. Can be used for serialization as well.
     */
    additionalData?: Record<string, unknown>;
    /**
     * The email property
     */
    email?: string;
    /**
     * The firstName property
     */
    firstName?: string;
    /**
     * The fullName property
     */
    fullName?: string;
    /**
     * The identityProvider property
     */
    identityProvider?: string;
    /**
     * The identityProviderUserId property
     */
    identityProviderUserId?: string;
    /**
     * The lastName property
     */
    lastName?: string;
    /**
     * The phoneNumber property
     */
    phoneNumber?: string;
    /**
     * The pictureUrl property
     */
    pictureUrl?: string;
    /**
     * The salesRepId property
     */
    salesRepId?: string;
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeAccountSetup(writer: SerializationWriter, accountSetup: Partial<AccountSetup> | undefined = {}) : void {
    writer.writeBooleanValue("isComplete", accountSetup.isComplete);
    writer.writeObjectValue<Step>("nextStep", accountSetup.nextStep, serializeStep);
    writer.writeNumberValue("percentageComplete", accountSetup.percentageComplete);
    writer.writeCollectionOfObjectValues<Step>("steps", accountSetup.steps, serializeStep);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeActivateSalesRepRequest(writer: SerializationWriter, activateSalesRepRequest: Partial<ActivateSalesRepRequest> | undefined = {}) : void {
    writer.writeStringValue("organizationSalesRepId", activateSalesRepRequest.organizationSalesRepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeAddItemRequest(writer: SerializationWriter, addItemRequest: Partial<AddItemRequest> | undefined = {}) : void {
    writer.writeNumberValue("quantity", addItemRequest.quantity);
    writer.writeStringValue("sku", addItemRequest.sku);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeAddress(writer: SerializationWriter, address: Partial<Address> | undefined = {}) : void {
    writer.writeStringValue("address1", address.address1);
    writer.writeStringValue("address2", address.address2);
    writer.writeEnumValue<AddressType>("addressType", address.addressType);
    writer.writeStringValue("city", address.city);
    writer.writeStringValue("code", address.code);
    writer.writeStringValue("country", address.country);
    writer.writeBooleanValue("default", address.defaultEscaped);
    writer.writeStringValue("externalId", address.externalId);
    writer.writeNumberValue("latitude", address.latitude);
    writer.writeNumberValue("longitude", address.longitude);
    writer.writeStringValue("stateProvince", address.stateProvince);
    writer.writeStringValue("zipPostalCode", address.zipPostalCode);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeBaseEntity(writer: SerializationWriter, baseEntity: Partial<BaseEntity> | undefined = {}) : void {
    writer.writeBooleanValue("active", baseEntity.active);
    writer.writeDateValue("createdAt", baseEntity.createdAt);
    writer.writeDateValue("updatedAt", baseEntity.updatedAt);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCard(writer: SerializationWriter, card: Partial<Card> | undefined = {}) : void {
    writer.writeStringValue("card_type", card.card_type);
    writer.writeNumberValue("created_at", card.created_at);
    writer.writeStringValue("customer_id", card.customer_id);
    writer.writeNumberValue("expiry_month", card.expiry_month);
    writer.writeNumberValue("expiry_year", card.expiry_year);
    writer.writeStringValue("funding_type", card.funding_type);
    writer.writeStringValue("gateway", card.gateway);
    writer.writeStringValue("gateway_account_id", card.gateway_account_id);
    writer.writeNumberValue("iin", card.iin);
    writer.writeStringValue("ip_address", card.ip_address);
    writer.writeNumberValue("last4", card.last4);
    writer.writeStringValue("masked_number", card.masked_number);
    writer.writeStringValue("object", card.object);
    writer.writeStringValue("payment_source_id", card.payment_source_id);
    writer.writeNumberValue("resource_version", card.resource_version);
    writer.writeStringValue("status", card.status);
    writer.writeNumberValue("updated_at", card.updated_at);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCartItem(writer: SerializationWriter, cartItem: Partial<CartItem> | undefined = {}) : void {
    serializeBaseEntity(writer, cartItem)
    writer.writeStringValue("imageName", cartItem.imageName);
    writer.writeNumberValue("minimumOrderQuantity", cartItem.minimumOrderQuantity);
    writer.writeNumberValue("price", cartItem.price);
    writer.writeStringValue("priceFormatted", cartItem.priceFormatted);
    writer.writeStringValue("productName", cartItem.productName);
    writer.writeNumberValue("quantity", cartItem.quantity);
    writer.writeNumberValue("quantityIncrement", cartItem.quantityIncrement);
    writer.writeStringValue("sku", cartItem.sku);
    writer.writeNumberValue("subTotal", cartItem.subTotal);
    writer.writeStringValue("subTotalFormatted", cartItem.subTotalFormatted);
    writer.writeAdditionalData(cartItem.additionalData);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCheckoutPageResponse(writer: SerializationWriter, checkoutPageResponse: Partial<CheckoutPageResponse> | undefined = {}) : void {
    writer.writeNumberValue("createdAt", checkoutPageResponse.createdAt);
    writer.writeBooleanValue("embed", checkoutPageResponse.embed);
    writer.writeNumberValue("expiresAt", checkoutPageResponse.expiresAt);
    writer.writeStringValue("id", checkoutPageResponse.id);
    writer.writeStringValue("state", checkoutPageResponse.state);
    writer.writeStringValue("type", checkoutPageResponse.type);
    writer.writeStringValue("url", checkoutPageResponse.url);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCompleteCheckoutRequest(writer: SerializationWriter, completeCheckoutRequest: Partial<CompleteCheckoutRequest> | undefined = {}) : void {
    writer.writeStringValue("channel", completeCheckoutRequest.channel);
    writer.writeStringValue("customerNumber", completeCheckoutRequest.customerNumber);
    writer.writeEnumValue<UserTypes>("userType", completeCheckoutRequest.userType);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCompleteCheckoutResponse(writer: SerializationWriter, completeCheckoutResponse: Partial<CompleteCheckoutResponse> | undefined = {}) : void {
    writer.writeStringValue("confirmationId", completeCheckoutResponse.confirmationId);
    writer.writeStringValue("orderNumber", completeCheckoutResponse.orderNumber);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeContact(writer: SerializationWriter, contact: Partial<Contact> | undefined = {}) : void {
    writer.writeEnumValue<ContactType>("contactType", contact.contactType);
    writer.writeBooleanValue("default", contact.defaultEscaped);
    writer.writeStringValue("emailAddress", contact.emailAddress);
    writer.writeStringValue("externalId", contact.externalId);
    writer.writeStringValue("fax", contact.fax);
    writer.writeStringValue("firstName", contact.firstName);
    writer.writeStringValue("fullName", contact.fullName);
    writer.writeStringValue("lastName", contact.lastName);
    writer.writeStringValue("phone", contact.phone);
    writer.writeStringValue("title", contact.title);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeContent(writer: SerializationWriter, content: Partial<Content> | undefined = {}) : void {
    writer.writeObjectValue<Card>("card", content.card, serializeCard);
    writer.writeObjectValue<Customer>("customer", content.customer, serializeCustomer);
    writer.writeObjectValue<Invoice>("invoice", content.invoice, serializeInvoice);
    writer.writeObjectValue<Subscription2>("subscription", content.subscription, serializeSubscription2);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateCartRequest(writer: SerializationWriter, createCartRequest: Partial<CreateCartRequest> | undefined = {}) : void {
    writer.writeNumberValue("quantity", createCartRequest.quantity);
    writer.writeStringValue("sku", createCartRequest.sku);
    writer.writeStringValue("userId", createCartRequest.userId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateCustomerRequest(writer: SerializationWriter, createCustomerRequest: Partial<CreateCustomerRequest> | undefined = {}) : void {
    writer.writeBooleanValue("active", createCustomerRequest.active);
    writer.writeCollectionOfObjectValues<Address>("addresses", createCustomerRequest.addresses, serializeAddress);
    writer.writeStringValue("companyEmail", createCustomerRequest.companyEmail);
    writer.writeStringValue("companyFax", createCustomerRequest.companyFax);
    writer.writeStringValue("companyName", createCustomerRequest.companyName);
    writer.writeStringValue("companyPhone", createCustomerRequest.companyPhone);
    writer.writeCollectionOfObjectValues<Contact>("contacts", createCustomerRequest.contacts, serializeContact);
    writer.writeStringValue("customerNumber", createCustomerRequest.customerNumber);
    writer.writeStringValue("pricing", createCustomerRequest.pricing);
    writer.writeStringValue("repNumber", createCustomerRequest.repNumber);
    writer.writeStringValue("terms", createCustomerRequest.terms);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateManyCustomersRequest(writer: SerializationWriter, createManyCustomersRequest: Partial<CreateManyCustomersRequest> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<CreateCustomerRequest>("customers", createManyCustomersRequest.customers, serializeCreateCustomerRequest);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateManyProductsRequest(writer: SerializationWriter, createManyProductsRequest: Partial<CreateManyProductsRequest> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<CreateProductRequest>("products", createManyProductsRequest.products, serializeCreateProductRequest);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateOrganizationRequest(writer: SerializationWriter, createOrganizationRequest: Partial<CreateOrganizationRequest> | undefined = {}) : void {
    writer.writeObjectValue<Organization2>("organization", createOrganizationRequest.organization, serializeOrganization2);
    writer.writeObjectValue<User>("user", createOrganizationRequest.user, serializeUser);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateOrganizationResponse(writer: SerializationWriter, createOrganizationResponse: Partial<CreateOrganizationResponse> | undefined = {}) : void {
    writer.writeStringValue("identityOrganizationId", createOrganizationResponse.identityOrganizationId);
    writer.writeStringValue("name", createOrganizationResponse.name);
    writer.writeStringValue("organizationId", createOrganizationResponse.organizationId);
    writer.writeStringValue("slug", createOrganizationResponse.slug);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateOrUpdateDataMappingRequest(writer: SerializationWriter, createOrUpdateDataMappingRequest: Partial<CreateOrUpdateDataMappingRequest> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<CreateOrUpdateDataMappingRequest_HeaderMappingField>("headerMappingFields", createOrUpdateDataMappingRequest.headerMappingFields, serializeCreateOrUpdateDataMappingRequest_HeaderMappingField);
    writer.writeStringValue("id", createOrUpdateDataMappingRequest.id);
    writer.writeStringValue("name", createOrUpdateDataMappingRequest.name);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateOrUpdateDataMappingRequest_HeaderMappingField(writer: SerializationWriter, createOrUpdateDataMappingRequest_HeaderMappingField: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField> | undefined = {}) : void {
    writer.writeStringValue("header", createOrUpdateDataMappingRequest_HeaderMappingField.header);
    writer.writeObjectValue<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping>("mapping", createOrUpdateDataMappingRequest_HeaderMappingField.mapping, serializeCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping(writer: SerializationWriter, createOrUpdateDataMappingRequest_HeaderMappingField_Mapping: Partial<CreateOrUpdateDataMappingRequest_HeaderMappingField_Mapping> | undefined = {}) : void {
    writer.writeStringValue("mappedHeaderName", createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.mappedHeaderName);
    writer.writeStringValue("uploadedHeaderName", createOrUpdateDataMappingRequest_HeaderMappingField_Mapping.uploadedHeaderName);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreatePriceTierRequest(writer: SerializationWriter, createPriceTierRequest: Partial<CreatePriceTierRequest> | undefined = {}) : void {
    writer.writeStringValue("currencyCode", createPriceTierRequest.currencyCode);
    writer.writeStringValue("name", createPriceTierRequest.name);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateProductRequest(writer: SerializationWriter, createProductRequest: Partial<CreateProductRequest> | undefined = {}) : void {
    writer.writeBooleanValue("active", createProductRequest.active);
    writer.writeStringValue("category", createProductRequest.category);
    writer.writeStringValue("description", createProductRequest.description);
    writer.writeStringValue("dimensions", createProductRequest.dimensions);
    writer.writeStringValue("dimensionsUnit", createProductRequest.dimensionsUnit);
    writer.writeStringValue("imageName", createProductRequest.imageName);
    writer.writeCollectionOfPrimitiveValues<string>("images", createProductRequest.images);
    writer.writeNumberValue("minimumOrderQuantity", createProductRequest.minimumOrderQuantity);
    writer.writeStringValue("name", createProductRequest.name);
    writer.writeNumberValue("price", createProductRequest.price);
    writer.writeNumberValue("quantityIncrement", createProductRequest.quantityIncrement);
    writer.writeNumberValue("quantityOnHand", createProductRequest.quantityOnHand);
    writer.writeStringValue("sku", createProductRequest.sku);
    writer.writeStringValue("upc", createProductRequest.upc);
    writer.writeNumberValue("weight", createProductRequest.weight);
    writer.writeStringValue("weightUnit", createProductRequest.weightUnit);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateSalesRepRequest(writer: SerializationWriter, createSalesRepRequest: Partial<CreateSalesRepRequest> | undefined = {}) : void {
    writer.writeStringValue("email", createSalesRepRequest.email);
    writer.writeStringValue("firstName", createSalesRepRequest.firstName);
    writer.writeStringValue("lastName", createSalesRepRequest.lastName);
    writer.writeStringValue("phoneNumber", createSalesRepRequest.phoneNumber);
    writer.writeStringValue("repNumber", createSalesRepRequest.repNumber);
    writer.writeCollectionOfPrimitiveValues<string>("roles", createSalesRepRequest.roles);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateUserRequest(writer: SerializationWriter, createUserRequest: Partial<CreateUserRequest> | undefined = {}) : void {
    writer.writeStringValue("email", createUserRequest.email);
    writer.writeStringValue("firstName", createUserRequest.firstName);
    writer.writeStringValue("lastName", createUserRequest.lastName);
    writer.writeStringValue("password", createUserRequest.password);
    writer.writeStringValue("phoneNumber", createUserRequest.phoneNumber);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCreateUserResponse(writer: SerializationWriter, createUserResponse: Partial<CreateUserResponse> | undefined = {}) : void {
    writer.writeStringValue("email", createUserResponse.email);
    writer.writeStringValue("firstName", createUserResponse.firstName);
    writer.writeStringValue("lastName", createUserResponse.lastName);
    writer.writeStringValue("phoneNumber", createUserResponse.phoneNumber);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCustomer(writer: SerializationWriter, customer: Partial<Customer> | undefined = {}) : void {
    writer.writeBooleanValue("allow_direct_debit", customer.allow_direct_debit);
    writer.writeStringValue("auto_collection", customer.auto_collection);
    writer.writeObjectValue<IngAddress>("billing_address", customer.billing_address, serializeIngAddress);
    writer.writeStringValue("card_status", customer.card_status);
    writer.writeStringValue("channel", customer.channel);
    writer.writeNumberValue("created_at", customer.created_at);
    writer.writeStringValue("created_from_ip", customer.created_from_ip);
    writer.writeBooleanValue("deleted", customer.deleted);
    writer.writeStringValue("email", customer.email);
    writer.writeNumberValue("excess_payments", customer.excess_payments);
    writer.writeStringValue("first_name", customer.first_name);
    writer.writeStringValue("id", customer.id);
    writer.writeStringValue("last_name", customer.last_name);
    writer.writeNumberValue("mrr", customer.mrr);
    writer.writeNumberValue("net_term_days", customer.net_term_days);
    writer.writeStringValue("object", customer.object);
    writer.writeObjectValue<PaymentMethod>("payment_method", customer.payment_method, serializePaymentMethod);
    writer.writeStringValue("pii_cleared", customer.pii_cleared);
    writer.writeStringValue("preferred_currency_code", customer.preferred_currency_code);
    writer.writeStringValue("primary_payment_source_id", customer.primary_payment_source_id);
    writer.writeNumberValue("promotional_credits", customer.promotional_credits);
    writer.writeNumberValue("refundable_credits", customer.refundable_credits);
    writer.writeNumberValue("resource_version", customer.resource_version);
    writer.writeStringValue("taxability", customer.taxability);
    writer.writeNumberValue("unbilled_charges", customer.unbilled_charges);
    writer.writeNumberValue("updated_at", customer.updated_at);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeCustomer2(writer: SerializationWriter, customer2: Partial<Customer2> | undefined = {}) : void {
    serializeBaseEntity(writer, customer2)
    writer.writeCollectionOfObjectValues<Address>("addresses", customer2.addresses, serializeAddress);
    writer.writeStringValue("companyEmail", customer2.companyEmail);
    writer.writeStringValue("companyFax", customer2.companyFax);
    writer.writeStringValue("companyName", customer2.companyName);
    writer.writeStringValue("companyPhone", customer2.companyPhone);
    writer.writeCollectionOfObjectValues<Contact>("contacts", customer2.contacts, serializeContact);
    writer.writeStringValue("customerId", customer2.customerId);
    writer.writeStringValue("customerNumber", customer2.customerNumber);
    writer.writeObjectValue<Address>("defaultBillingAddress", customer2.defaultBillingAddress, serializeAddress);
    writer.writeObjectValue<Address>("defaultShippingAddress", customer2.defaultShippingAddress, serializeAddress);
    writer.writeStringValue("organizationId", customer2.organizationId);
    writer.writeStringValue("pricing", customer2.pricing);
    writer.writeStringValue("repNumber", customer2.repNumber);
    writer.writeStringValue("terms", customer2.terms);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeDataMapping(writer: SerializationWriter, dataMapping: Partial<DataMapping> | undefined = {}) : void {
    writer.writeStringValue("description", dataMapping.description);
    writer.writeCollectionOfObjectValues<HeaderColumnMap>("headerMappingFields", dataMapping.headerMappingFields, serializeHeaderColumnMap);
    writer.writeStringValue("id", dataMapping.id);
    writer.writeStringValue("name", dataMapping.name);
    writer.writeStringValue("organizationId", dataMapping.organizationId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeDeactivateSalesRepRequest(writer: SerializationWriter, deactivateSalesRepRequest: Partial<DeactivateSalesRepRequest> | undefined = {}) : void {
    writer.writeStringValue("organizationSalesRepId", deactivateSalesRepRequest.organizationSalesRepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeDeletePriceTierRequest(writer: SerializationWriter, deletePriceTierRequest: Partial<DeletePriceTierRequest> | undefined = {}) : void {
    writer.writeStringValue("name", deletePriceTierRequest.name);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeDeleteSalesRepRequest(writer: SerializationWriter, deleteSalesRepRequest: Partial<DeleteSalesRepRequest> | undefined = {}) : void {
    writer.writeStringValue("organizationSalesRepId", deleteSalesRepRequest.organizationSalesRepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeEntitlementsResponse(writer: SerializationWriter, entitlementsResponse: Partial<EntitlementsResponse> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<ListOfEntitlements>("entitlements", entitlementsResponse.entitlements, serializeListOfEntitlements);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeErrorResponse(writer: SerializationWriter, errorResponse: Partial<ErrorResponse> | undefined = {}) : void {
    writer.writeObjectValue<ErrorResponse_errors>("errors", errorResponse.errors, serializeErrorResponse_errors);
    writer.writeStringValue("message", errorResponse.messageEscaped ?? "One or more errors occurred!");
    writer.writeNumberValue("statusCode", errorResponse.statusCode);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeErrorResponse_errors(writer: SerializationWriter, errorResponse_errors: Partial<ErrorResponse_errors> | undefined = {}) : void {
    writer.writeAdditionalData(errorResponse_errors.additionalData);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeExistsResponse(writer: SerializationWriter, existsResponse: Partial<ExistsResponse> | undefined = {}) : void {
    writer.writeBooleanValue("exitsInOrganization", existsResponse.exitsInOrganization);
    writer.writeBooleanValue("identityExists", existsResponse.identityExists);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeGetOrganizationResponse(writer: SerializationWriter, getOrganizationResponse: Partial<GetOrganizationResponse> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<ListOfEntitlements>("entitlements", getOrganizationResponse.entitlements, serializeListOfEntitlements);
    writer.writeObjectValue<Organization>("organization", getOrganizationResponse.organization, serializeOrganization);
    writer.writeCollectionOfObjectValues<Subscription>("subscriptions", getOrganizationResponse.subscriptions, serializeSubscription);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeHeaderColumnMap(writer: SerializationWriter, headerColumnMap: Partial<HeaderColumnMap> | undefined = {}) : void {
    writer.writeStringValue("header", headerColumnMap.header);
    writer.writeObjectValue<HeaderMapping>("mapping", headerColumnMap.mapping, serializeHeaderMapping);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeHeaderMapping(writer: SerializationWriter, headerMapping: Partial<HeaderMapping> | undefined = {}) : void {
    writer.writeStringValue("mappedHeaderName", headerMapping.mappedHeaderName);
    writer.writeStringValue("uploadedHeaderName", headerMapping.uploadedHeaderName);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeImageAssignment(writer: SerializationWriter, imageAssignment: Partial<ImageAssignment> | undefined = {}) : void {
    writer.writeEnumValue<ImageFormat>("format", imageAssignment.format);
    writer.writeStringValue("imageId", imageAssignment.imageId);
    writer.writeStringValue("imageName", imageAssignment.imageName);
    writer.writeNumberValue("position", imageAssignment.position);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeIngAddress(writer: SerializationWriter, ingAddress: Partial<IngAddress> | undefined = {}) : void {
    writer.writeStringValue("city", ingAddress.city);
    writer.writeStringValue("country", ingAddress.country);
    writer.writeStringValue("first_name", ingAddress.first_name);
    writer.writeStringValue("last_name", ingAddress.last_name);
    writer.writeStringValue("line1", ingAddress.line1);
    writer.writeStringValue("object", ingAddress.object);
    writer.writeStringValue("state", ingAddress.state);
    writer.writeStringValue("state_code", ingAddress.state_code);
    writer.writeStringValue("validation_status", ingAddress.validation_status);
    writer.writeNumberValue("zip", ingAddress.zip);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeInternalErrorResponse(writer: SerializationWriter, internalErrorResponse: Partial<InternalErrorResponse> | undefined = {}) : void {
    writer.writeNumberValue("code", internalErrorResponse.code);
    writer.writeStringValue("note", internalErrorResponse.note ?? "See application log for stack trace.");
    writer.writeStringValue("reason", internalErrorResponse.reason ?? "Something unexpected has happened");
    writer.writeStringValue("status", internalErrorResponse.status ?? "Internal Server Error!");
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeInvoice(writer: SerializationWriter, invoice: Partial<Invoice> | undefined = {}) : void {
    writer.writeObjectValue("adjustment_credit_notes", invoice.adjustment_credit_notes);
    writer.writeNumberValue("amount_adjusted", invoice.amount_adjusted);
    writer.writeNumberValue("amount_due", invoice.amount_due);
    writer.writeNumberValue("amount_paid", invoice.amount_paid);
    writer.writeNumberValue("amount_to_collect", invoice.amount_to_collect);
    writer.writeObjectValue("applied_credits", invoice.applied_credits);
    writer.writeStringValue("base_currency_code", invoice.base_currency_code);
    writer.writeObjectValue<IngAddress>("billing_address", invoice.billing_address, serializeIngAddress);
    writer.writeStringValue("channel", invoice.channel);
    writer.writeNumberValue("credits_applied", invoice.credits_applied);
    writer.writeStringValue("currency_code", invoice.currency_code);
    writer.writeStringValue("customer_id", invoice.customer_id);
    writer.writeNumberValue("date", invoice.date);
    writer.writeBooleanValue("deleted", invoice.deleted);
    writer.writeNumberValue("due_date", invoice.due_date);
    writer.writeObjectValue("dunning_attempts", invoice.dunning_attempts);
    writer.writeNumberValue("exchange_rate", invoice.exchange_rate);
    writer.writeBooleanValue("first_invoice", invoice.first_invoice);
    writer.writeNumberValue("generated_at", invoice.generated_at);
    writer.writeBooleanValue("has_advance_charges", invoice.has_advance_charges);
    writer.writeNumberValue("id", invoice.id);
    writer.writeObjectValue("issued_credit_notes", invoice.issued_credit_notes);
    writer.writeBooleanValue("is_gifted", invoice.is_gifted);
    writer.writeCollectionOfObjectValues<LineItem>("line_items", invoice.line_items, serializeLineItem);
    writer.writeObjectValue("linked_orders", invoice.linked_orders);
    writer.writeCollectionOfObjectValues<LinkedPayment>("linked_payments", invoice.linked_payments, serializeLinkedPayment);
    writer.writeNumberValue("net_term_days", invoice.net_term_days);
    writer.writeNumberValue("new_sales_amount", invoice.new_sales_amount);
    writer.writeStringValue("object", invoice.object);
    writer.writeNumberValue("paid_at", invoice.paid_at);
    writer.writeStringValue("price_type", invoice.price_type);
    writer.writeBooleanValue("recurring", invoice.recurring);
    writer.writeNumberValue("resource_version", invoice.resource_version);
    writer.writeNumberValue("round_off_amount", invoice.round_off_amount);
    writer.writeObjectValue<IngAddress>("shipping_address", invoice.shipping_address, serializeIngAddress);
    writer.writeStringValue("status", invoice.status);
    writer.writeStringValue("subscription_id", invoice.subscription_id);
    writer.writeNumberValue("sub_total", invoice.sub_total);
    writer.writeNumberValue("tax", invoice.tax);
    writer.writeBooleanValue("term_finalized", invoice.term_finalized);
    writer.writeNumberValue("total", invoice.total);
    writer.writeNumberValue("updated_at", invoice.updated_at);
    writer.writeNumberValue("write_off_amount", invoice.write_off_amount);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeLineItem(writer: SerializationWriter, lineItem: Partial<LineItem> | undefined = {}) : void {
    writer.writeNumberValue("amount", lineItem.amount);
    writer.writeStringValue("customer_id", lineItem.customer_id);
    writer.writeNumberValue("date_from", lineItem.date_from);
    writer.writeNumberValue("date_to", lineItem.date_to);
    writer.writeStringValue("description", lineItem.description);
    writer.writeNumberValue("discount_amount", lineItem.discount_amount);
    writer.writeStringValue("entity_id", lineItem.entity_id);
    writer.writeStringValue("entity_type", lineItem.entity_type);
    writer.writeStringValue("id", lineItem.id);
    writer.writeBooleanValue("is_taxed", lineItem.is_taxed);
    writer.writeNumberValue("item_level_discount_amount", lineItem.item_level_discount_amount);
    writer.writeStringValue("object", lineItem.object);
    writer.writeStringValue("pricing_model", lineItem.pricing_model);
    writer.writeNumberValue("quantity", lineItem.quantity);
    writer.writeStringValue("subscription_id", lineItem.subscription_id);
    writer.writeNumberValue("tax_amount", lineItem.tax_amount);
    writer.writeStringValue("tax_exempt_reason", lineItem.tax_exempt_reason);
    writer.writeNumberValue("unit_amount", lineItem.unit_amount);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeLinkedPayment(writer: SerializationWriter, linkedPayment: Partial<LinkedPayment> | undefined = {}) : void {
    writer.writeNumberValue("applied_amount", linkedPayment.applied_amount);
    writer.writeNumberValue("applied_at", linkedPayment.applied_at);
    writer.writeNumberValue("txn_amount", linkedPayment.txn_amount);
    writer.writeNumberValue("txn_date", linkedPayment.txn_date);
    writer.writeStringValue("txn_id", linkedPayment.txn_id);
    writer.writeStringValue("txn_status", linkedPayment.txn_status);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeListOfEntitlements(writer: SerializationWriter, listOfEntitlements: Partial<ListOfEntitlements> | undefined = {}) : void {
    writer.writeObjectValue<SubscriptionEntitlement>("subscriptionEntitlement", listOfEntitlements.subscriptionEntitlement, serializeSubscriptionEntitlement);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeListOrganizationSalesRepsResponse(writer: SerializationWriter, listOrganizationSalesRepsResponse: Partial<ListOrganizationSalesRepsResponse> | undefined = {}) : void {
    writer.writeBooleanValue("active", listOrganizationSalesRepsResponse.active);
    writer.writeStringValue("email", listOrganizationSalesRepsResponse.email);
    writer.writeStringValue("firstName", listOrganizationSalesRepsResponse.firstName);
    writer.writeStringValue("lastName", listOrganizationSalesRepsResponse.lastName);
    writer.writeStringValue("organizationId", listOrganizationSalesRepsResponse.organizationId);
    writer.writeStringValue("organizationSalesRepId", listOrganizationSalesRepsResponse.organizationSalesRepId);
    writer.writeStringValue("phoneNumber", listOrganizationSalesRepsResponse.phoneNumber);
    writer.writeStringValue("repNumber", listOrganizationSalesRepsResponse.repNumber);
    writer.writeStringValue("salesRepId", listOrganizationSalesRepsResponse.salesRepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeListSalesRepOrganizationsResponse(writer: SerializationWriter, listSalesRepOrganizationsResponse: Partial<ListSalesRepOrganizationsResponse> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<OrganizationSalesRep>("salesRepOrganizations", listSalesRepOrganizationsResponse.salesRepOrganizations, serializeOrganizationSalesRep);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeListUserOrganizationsResponse(writer: SerializationWriter, listUserOrganizationsResponse: Partial<ListUserOrganizationsResponse> | undefined = {}) : void {
    writer.writeCollectionOfObjectValues<Organization>("organizations", listUserOrganizationsResponse.organizations, serializeOrganization);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeNotFound(writer: SerializationWriter, notFound: Partial<NotFound> | undefined = {}) : void {
    writer.writeNumberValue("statusCode", notFound.statusCode);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeOk(writer: SerializationWriter, ok: Partial<Ok> | undefined = {}) : void {
    writer.writeNumberValue("statusCode", ok.statusCode);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeOrganization(writer: SerializationWriter, organization: Partial<Organization> | undefined = {}) : void {
    serializeBaseEntity(writer, organization)
    writer.writeObjectValue<AccountSetup>("accountSetup", organization.accountSetup, serializeAccountSetup);
    writer.writeStringValue("billingAddress1", organization.billingAddress1);
    writer.writeStringValue("billingAddress2", organization.billingAddress2);
    writer.writeStringValue("billingCity", organization.billingCity);
    writer.writeStringValue("billingCountry", organization.billingCountry);
    writer.writeStringValue("billingPostalCode", organization.billingPostalCode);
    writer.writeStringValue("billingStateProvince", organization.billingStateProvince);
    writer.writeStringValue("email", organization.email);
    writer.writeStringValue("identityOrganizationId", organization.identityOrganizationId);
    writer.writeNumberValue("latitude", organization.latitude);
    writer.writeStringValue("logoUrl", organization.logoUrl);
    writer.writeNumberValue("longitude", organization.longitude);
    writer.writeStringValue("name", organization.name);
    writer.writeStringValue("organizationId", organization.organizationId);
    writer.writeStringValue("phoneNumber", organization.phoneNumber);
    writer.writeCollectionOfObjectValues<PriceTier>("priceTiers", organization.priceTiers, serializePriceTier);
    writer.writeCollectionOfObjectValues<Subscription>("subscriptions", organization.subscriptions, serializeSubscription);
    if(organization.terms)
    writer.writeEnumValue<Term>("terms", ...organization.terms);
    writer.writeStringValue("websiteUrl", organization.websiteUrl);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeOrganization2(writer: SerializationWriter, organization2: Partial<Organization2> | undefined = {}) : void {
    writer.writeStringValue("billingAddress1", organization2.billingAddress1);
    writer.writeStringValue("billingAddress2", organization2.billingAddress2);
    writer.writeStringValue("billingCity", organization2.billingCity);
    writer.writeStringValue("billingCountry", organization2.billingCountry);
    writer.writeStringValue("billingPostalCode", organization2.billingPostalCode);
    writer.writeStringValue("billingStateProvince", organization2.billingStateProvince);
    writer.writeStringValue("email", organization2.email);
    writer.writeStringValue("name", organization2.name);
    writer.writeStringValue("phoneNumber", organization2.phoneNumber);
    writer.writeStringValue("website", organization2.website);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeOrganizationSalesRep(writer: SerializationWriter, organizationSalesRep: Partial<OrganizationSalesRep> | undefined = {}) : void {
    serializeBaseEntity(writer, organizationSalesRep)
    writer.writeStringValue("email", organizationSalesRep.email);
    writer.writeStringValue("firstName", organizationSalesRep.firstName);
    writer.writeStringValue("identityOrganizationId", organizationSalesRep.identityOrganizationId);
    writer.writeStringValue("identityProviderUserId", organizationSalesRep.identityProviderUserId);
    writer.writeStringValue("lastName", organizationSalesRep.lastName);
    writer.writeStringValue("organizationId", organizationSalesRep.organizationId);
    writer.writeStringValue("organizationName", organizationSalesRep.organizationName);
    writer.writeStringValue("organizationSalesRepId", organizationSalesRep.organizationSalesRepId);
    writer.writeStringValue("phoneNumber", organizationSalesRep.phoneNumber);
    writer.writeStringValue("repNumber", organizationSalesRep.repNumber);
    writer.writeStringValue("salesRepId", organizationSalesRep.salesRepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializePagedResponseOfCustomer(writer: SerializationWriter, pagedResponseOfCustomer: Partial<PagedResponseOfCustomer> | undefined = {}) : void {
    writer.writeNumberValue("currentPage", pagedResponseOfCustomer.currentPage);
    writer.writeCollectionOfObjectValues<Customer2>("data", pagedResponseOfCustomer.data, serializeCustomer2);
    writer.writeNumberValue("totalPages", pagedResponseOfCustomer.totalPages);
    writer.writeNumberValue("totalRecords", pagedResponseOfCustomer.totalRecords);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializePagedResponseOfProduct(writer: SerializationWriter, pagedResponseOfProduct: Partial<PagedResponseOfProduct> | undefined = {}) : void {
    writer.writeNumberValue("currentPage", pagedResponseOfProduct.currentPage);
    writer.writeCollectionOfObjectValues<Product>("data", pagedResponseOfProduct.data, serializeProduct);
    writer.writeNumberValue("totalPages", pagedResponseOfProduct.totalPages);
    writer.writeNumberValue("totalRecords", pagedResponseOfProduct.totalRecords);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializePaymentMethod(writer: SerializationWriter, paymentMethod: Partial<PaymentMethod> | undefined = {}) : void {
    writer.writeStringValue("gateway", paymentMethod.gateway);
    writer.writeStringValue("gateway_account_id", paymentMethod.gateway_account_id);
    writer.writeStringValue("object", paymentMethod.object);
    writer.writeStringValue("reference_id", paymentMethod.reference_id);
    writer.writeStringValue("status", paymentMethod.status);
    writer.writeStringValue("type", paymentMethod.type);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializePortalSessionLinkResponse(writer: SerializationWriter, portalSessionLinkResponse: Partial<PortalSessionLinkResponse> | undefined = {}) : void {
    writer.writeStringValue("sessionUrl", portalSessionLinkResponse.sessionUrl);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializePriceTier(writer: SerializationWriter, priceTier: Partial<PriceTier> | undefined = {}) : void {
    writer.writeEnumValue<CurrencyCode>("currencyCode", priceTier.currencyCode);
    writer.writeStringValue("name", priceTier.name);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeProduct(writer: SerializationWriter, product: Partial<Product> | undefined = {}) : void {
    serializeBaseEntity(writer, product)
    writer.writeStringValue("category", product.category);
    writer.writeEnumValue<CurrencyCode>("currencyCode", product.currencyCode);
    writer.writeStringValue("description", product.description);
    writer.writeStringValue("dimensions", product.dimensions);
    writer.writeStringValue("dimensionsUnit", product.dimensionsUnit);
    writer.writeObjectValue<Product_dynamicProperties>("dynamicProperties", product.dynamicProperties, serializeProduct_dynamicProperties);
    writer.writeStringValue("imageName", product.imageName);
    writer.writeCollectionOfObjectValues<ImageAssignment>("images", product.images, serializeImageAssignment);
    writer.writeNumberValue("minimumOrderQuantity", product.minimumOrderQuantity);
    writer.writeStringValue("name", product.name);
    writer.writeStringValue("organizationId", product.organizationId);
    writer.writeNumberValue("price", product.price);
    writer.writeCollectionOfObjectValues<ProductPrice>("prices", product.prices, serializeProductPrice);
    writer.writeStringValue("productId", product.productId);
    writer.writeEnumValue<ProductType>("productType", product.productType);
    writer.writeNumberValue("quantityIncrement", product.quantityIncrement);
    writer.writeNumberValue("quantityOnHand", product.quantityOnHand);
    writer.writeStringValue("sku", product.sku);
    writer.writeStringValue("upc", product.upc);
    writer.writeNumberValue("weight", product.weight);
    writer.writeStringValue("weightUnit", product.weightUnit);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeProduct_dynamicProperties(writer: SerializationWriter, product_dynamicProperties: Partial<Product_dynamicProperties> | undefined = {}) : void {
    writer.writeAdditionalData(product_dynamicProperties.additionalData);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeProductPrice(writer: SerializationWriter, productPrice: Partial<ProductPrice> | undefined = {}) : void {
    writer.writeNumberValue("atQuantity", productPrice.atQuantity);
    writer.writeNumberValue("price", productPrice.price);
    writer.writeStringValue("tier", productPrice.tier);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeReadmeAuthResponse(writer: SerializationWriter, readmeAuthResponse: Partial<ReadmeAuthResponse> | undefined = {}) : void {
    writer.writeStringValue("readmeAuthUrl", readmeAuthResponse.readmeAuthUrl);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeRep(writer: SerializationWriter, rep: Partial<Rep> | undefined = {}) : void {
    writer.writeStringValue("email", rep.email);
    writer.writeStringValue("firstName", rep.firstName);
    writer.writeStringValue("fullName", rep.fullName);
    writer.writeStringValue("lastName", rep.lastName);
    writer.writeStringValue("organizationSalesRepId", rep.organizationSalesRepId);
    writer.writeStringValue("phoneNumber", rep.phoneNumber);
    writer.writeStringValue("repNumber", rep.repNumber);
    writer.writeStringValue("salesRepId", rep.salesRepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeSalesRep(writer: SerializationWriter, salesRep: Partial<SalesRep> | undefined = {}) : void {
    serializeBaseEntity(writer, salesRep)
    writer.writeStringValue("email", salesRep.email);
    writer.writeStringValue("firstName", salesRep.firstName);
    writer.writeStringValue("fullName", salesRep.fullName);
    writer.writeStringValue("identityProvider", salesRep.identityProvider);
    writer.writeStringValue("identityProviderUserId", salesRep.identityProviderUserId);
    writer.writeStringValue("lastName", salesRep.lastName);
    writer.writeStringValue("phoneNumber", salesRep.phoneNumber);
    writer.writeStringValue("pictureUrl", salesRep.pictureUrl);
    writer.writeStringValue("salesRepId", salesRep.salesRepId);
    writer.writeAdditionalData(salesRep.additionalData);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeShoppingCart(writer: SerializationWriter, shoppingCart: Partial<ShoppingCart> | undefined = {}) : void {
    serializeBaseEntity(writer, shoppingCart)
    writer.writeObjectValue<Address>("billingAddress", shoppingCart.billingAddress, serializeAddress);
    writer.writeCollectionOfObjectValues<CartItem>("cartItems", shoppingCart.cartItems, serializeCartItem);
    writer.writeStringValue("companyEmail", shoppingCart.companyEmail);
    writer.writeStringValue("companyFax", shoppingCart.companyFax);
    writer.writeStringValue("companyName", shoppingCart.companyName);
    writer.writeStringValue("companyPhone", shoppingCart.companyPhone);
    writer.writeStringValue("currencyCode", shoppingCart.currencyCode);
    writer.writeStringValue("customerNumber", shoppingCart.customerNumber);
    writer.writeStringValue("notes", shoppingCart.notes);
    writer.writeNumberValue("orderTotal", shoppingCart.orderTotal);
    writer.writeStringValue("orderTotalFormatted", shoppingCart.orderTotalFormatted);
    writer.writeStringValue("orderType", shoppingCart.orderType);
    writer.writeStringValue("organizationId", shoppingCart.organizationId);
    writer.writeStringValue("poNumber", shoppingCart.poNumber);
    writer.writeStringValue("priceTier", shoppingCart.priceTier);
    writer.writeObjectValue<Rep>("primarySalesRep", shoppingCart.primarySalesRep, serializeRep);
    writer.writeNumberValue("shipping", shoppingCart.shipping);
    writer.writeObjectValue<Address>("shippingAddress", shoppingCart.shippingAddress, serializeAddress);
    writer.writeStringValue("shippingFormatted", shoppingCart.shippingFormatted);
    writer.writeStringValue("shippingMethod", shoppingCart.shippingMethod);
    writer.writeStringValue("shoppingCartId", shoppingCart.shoppingCartId);
    writer.writeNumberValue("subtotal", shoppingCart.subtotal);
    writer.writeStringValue("subtotalFormatted", shoppingCart.subtotalFormatted);
    writer.writeObjectValue<Rep>("takenBySalesRep", shoppingCart.takenBySalesRep, serializeRep);
    writer.writeNumberValue("tax", shoppingCart.tax);
    writer.writeStringValue("taxFormatted", shoppingCart.taxFormatted);
    writer.writeStringValue("terms", shoppingCart.terms);
    writer.writeStringValue("userId", shoppingCart.userId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeStep(writer: SerializationWriter, step: Partial<Step> | undefined = {}) : void {
    writer.writeBooleanValue("isComplete", step.isComplete);
    writer.writeStringValue("name", step.name);
    writer.writeNumberValue("order", step.order);
    writer.writeStringValue("stepId", step.stepId);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeSubscription(writer: SerializationWriter, subscription: Partial<Subscription> | undefined = {}) : void {
    writer.writeStringValue("id", subscription.id);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeSubscription2(writer: SerializationWriter, subscription2: Partial<Subscription2> | undefined = {}) : void {
    writer.writeNumberValue("activated_at", subscription2.activated_at);
    writer.writeNumberValue("billing_period", subscription2.billing_period);
    writer.writeStringValue("billing_period_unit", subscription2.billing_period_unit);
    writer.writeStringValue("channel", subscription2.channel);
    writer.writeNumberValue("created_at", subscription2.created_at);
    writer.writeStringValue("created_from_ip", subscription2.created_from_ip);
    writer.writeStringValue("currency_code", subscription2.currency_code);
    writer.writeNumberValue("current_term_end", subscription2.current_term_end);
    writer.writeNumberValue("current_term_start", subscription2.current_term_start);
    writer.writeStringValue("customer_id", subscription2.customer_id);
    writer.writeBooleanValue("deleted", subscription2.deleted);
    writer.writeNumberValue("due_invoices_count", subscription2.due_invoices_count);
    writer.writeBooleanValue("has_scheduled_advance_invoices", subscription2.has_scheduled_advance_invoices);
    writer.writeBooleanValue("has_scheduled_changes", subscription2.has_scheduled_changes);
    writer.writeStringValue("id", subscription2.id);
    writer.writeNumberValue("mrr", subscription2.mrr);
    writer.writeNumberValue("next_billing_at", subscription2.next_billing_at);
    writer.writeStringValue("object", subscription2.object);
    writer.writeNumberValue("resource_version", subscription2.resource_version);
    writer.writeObjectValue<IngAddress>("shipping_address", subscription2.shipping_address, serializeIngAddress);
    writer.writeNumberValue("started_at", subscription2.started_at);
    writer.writeStringValue("status", subscription2.status);
    writer.writeCollectionOfObjectValues<SubscriptionItem>("subscription_items", subscription2.subscription_items, serializeSubscriptionItem);
    writer.writeNumberValue("updated_at", subscription2.updated_at);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeSubscriptionCreatedRequest(writer: SerializationWriter, subscriptionCreatedRequest: Partial<SubscriptionCreatedRequest> | undefined = {}) : void {
    writer.writeStringValue("api_version", subscriptionCreatedRequest.api_version);
    writer.writeObjectValue<Content>("content", subscriptionCreatedRequest.content, serializeContent);
    writer.writeStringValue("event_type", subscriptionCreatedRequest.event_type);
    writer.writeStringValue("id", subscriptionCreatedRequest.id);
    writer.writeStringValue("object", subscriptionCreatedRequest.object);
    writer.writeNumberValue("occurred_at", subscriptionCreatedRequest.occurred_at);
    writer.writeStringValue("source", subscriptionCreatedRequest.source);
    writer.writeStringValue("webhook_status", subscriptionCreatedRequest.webhook_status);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeSubscriptionEntitlement(writer: SerializationWriter, subscriptionEntitlement: Partial<SubscriptionEntitlement> | undefined = {}) : void {
    writer.writeStringValue("featureId", subscriptionEntitlement.featureId);
    writer.writeStringValue("featureName", subscriptionEntitlement.featureName);
    writer.writeStringValue("id", subscriptionEntitlement.id);
    writer.writeBooleanValue("isOverridden", subscriptionEntitlement.isOverridden);
    writer.writeStringValue("name", subscriptionEntitlement.name);
    writer.writeStringValue("subscriptionId", subscriptionEntitlement.subscriptionId);
    writer.writeStringValue("value", subscriptionEntitlement.value);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeSubscriptionItem(writer: SerializationWriter, subscriptionItem: Partial<SubscriptionItem> | undefined = {}) : void {
    writer.writeNumberValue("amount", subscriptionItem.amount);
    writer.writeNumberValue("free_quantity", subscriptionItem.free_quantity);
    writer.writeStringValue("item_price_id", subscriptionItem.item_price_id);
    writer.writeStringValue("item_type", subscriptionItem.item_type);
    writer.writeStringValue("object", subscriptionItem.object);
    writer.writeNumberValue("quantity", subscriptionItem.quantity);
    writer.writeNumberValue("unit_price", subscriptionItem.unit_price);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeUpdateItemRequest(writer: SerializationWriter, updateItemRequest: Partial<UpdateItemRequest> | undefined = {}) : void {
    writer.writeNumberValue("quantity", updateItemRequest.quantity);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeUpdatePriceTierRequest(writer: SerializationWriter, updatePriceTierRequest: Partial<UpdatePriceTierRequest> | undefined = {}) : void {
    writer.writeStringValue("currencyCode", updatePriceTierRequest.currencyCode);
    writer.writeStringValue("newName", updatePriceTierRequest.newName);
    writer.writeStringValue("oldName", updatePriceTierRequest.oldName);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeUser(writer: SerializationWriter, user: Partial<User> | undefined = {}) : void {
    writer.writeStringValue("email", user.email);
    writer.writeStringValue("firstName", user.firstName);
    writer.writeStringValue("lastName", user.lastName);
    writer.writeStringValue("password", user.password);
    writer.writeStringValue("phoneNumber", user.phoneNumber);
}
/**
 * Serializes information the current object
 * @param writer Serialization writer to use to serialize this model
 */
export function serializeUserExistsResponse(writer: SerializationWriter, userExistsResponse: Partial<UserExistsResponse> | undefined = {}) : void {
    writer.writeBooleanValue("userExists", userExistsResponse.userExists);
}
export interface ShoppingCart extends BaseEntity, Parsable {
    /**
     * The billingAddress property
     */
    billingAddress?: Address;
    /**
     * The cartItems property
     */
    cartItems?: CartItem[];
    /**
     * The companyEmail property
     */
    companyEmail?: string;
    /**
     * The companyFax property
     */
    companyFax?: string;
    /**
     * The companyName property
     */
    companyName?: string;
    /**
     * The companyPhone property
     */
    companyPhone?: string;
    /**
     * The currencyCode property
     */
    currencyCode?: string;
    /**
     * The customerNumber property
     */
    customerNumber?: string;
    /**
     * The notes property
     */
    notes?: string;
    /**
     * The orderTotal property
     */
    orderTotal?: number;
    /**
     * The orderTotalFormatted property
     */
    orderTotalFormatted?: string;
    /**
     * The orderType property
     */
    orderType?: string;
    /**
     * The organizationId property
     */
    organizationId?: string;
    /**
     * The poNumber property
     */
    poNumber?: string;
    /**
     * The priceTier property
     */
    priceTier?: string;
    /**
     * The primarySalesRep property
     */
    primarySalesRep?: Rep;
    /**
     * The shipping property
     */
    shipping?: number;
    /**
     * The shippingAddress property
     */
    shippingAddress?: Address;
    /**
     * The shippingFormatted property
     */
    shippingFormatted?: string;
    /**
     * The shippingMethod property
     */
    shippingMethod?: string;
    /**
     * The shoppingCartId property
     */
    shoppingCartId?: string;
    /**
     * The subtotal property
     */
    subtotal?: number;
    /**
     * The subtotalFormatted property
     */
    subtotalFormatted?: string;
    /**
     * The takenBySalesRep property
     */
    takenBySalesRep?: Rep;
    /**
     * The tax property
     */
    tax?: number;
    /**
     * The taxFormatted property
     */
    taxFormatted?: string;
    /**
     * The terms property
     */
    terms?: string;
    /**
     * The userId property
     */
    userId?: string;
}
export interface Step extends Parsable {
    /**
     * The isComplete property
     */
    isComplete?: boolean;
    /**
     * The name property
     */
    name?: string;
    /**
     * The order property
     */
    order?: number;
    /**
     * The stepId property
     */
    stepId?: string;
}
export interface Subscription extends Parsable {
    /**
     * The id property
     */
    id?: string;
}
export interface Subscription2 extends Parsable {
    /**
     * The activated_at property
     */
    activated_at?: number;
    /**
     * The billing_period property
     */
    billing_period?: number;
    /**
     * The billing_period_unit property
     */
    billing_period_unit?: string;
    /**
     * The channel property
     */
    channel?: string;
    /**
     * The created_at property
     */
    created_at?: number;
    /**
     * The created_from_ip property
     */
    created_from_ip?: string;
    /**
     * The currency_code property
     */
    currency_code?: string;
    /**
     * The current_term_end property
     */
    current_term_end?: number;
    /**
     * The current_term_start property
     */
    current_term_start?: number;
    /**
     * The customer_id property
     */
    customer_id?: string;
    /**
     * The deleted property
     */
    deleted?: boolean;
    /**
     * The due_invoices_count property
     */
    due_invoices_count?: number;
    /**
     * The has_scheduled_advance_invoices property
     */
    has_scheduled_advance_invoices?: boolean;
    /**
     * The has_scheduled_changes property
     */
    has_scheduled_changes?: boolean;
    /**
     * The id property
     */
    id?: string;
    /**
     * The mrr property
     */
    mrr?: number;
    /**
     * The next_billing_at property
     */
    next_billing_at?: number;
    /**
     * The object property
     */
    object?: string;
    /**
     * The resource_version property
     */
    resource_version?: number;
    /**
     * The shipping_address property
     */
    shipping_address?: IngAddress;
    /**
     * The started_at property
     */
    started_at?: number;
    /**
     * The status property
     */
    status?: string;
    /**
     * The subscription_items property
     */
    subscription_items?: SubscriptionItem[];
    /**
     * The updated_at property
     */
    updated_at?: number;
}
export interface SubscriptionCreatedRequest extends Parsable {
    /**
     * The api_version property
     */
    api_version?: string;
    /**
     * The content property
     */
    content?: Content;
    /**
     * The event_type property
     */
    event_type?: string;
    /**
     * The id property
     */
    id?: string;
    /**
     * The object property
     */
    object?: string;
    /**
     * The occurred_at property
     */
    occurred_at?: number;
    /**
     * The source property
     */
    source?: string;
    /**
     * The webhook_status property
     */
    webhook_status?: string;
}
export interface SubscriptionEntitlement extends Parsable {
    /**
     * The featureId property
     */
    featureId?: string;
    /**
     * The featureName property
     */
    featureName?: string;
    /**
     * The id property
     */
    id?: string;
    /**
     * The isOverridden property
     */
    isOverridden?: boolean;
    /**
     * The name property
     */
    name?: string;
    /**
     * The subscriptionId property
     */
    subscriptionId?: string;
    /**
     * The value property
     */
    value?: string;
}
export interface SubscriptionItem extends Parsable {
    /**
     * The amount property
     */
    amount?: number;
    /**
     * The free_quantity property
     */
    free_quantity?: number;
    /**
     * The item_price_id property
     */
    item_price_id?: string;
    /**
     * The item_type property
     */
    item_type?: string;
    /**
     * The object property
     */
    object?: string;
    /**
     * The quantity property
     */
    quantity?: number;
    /**
     * The unit_price property
     */
    unit_price?: number;
}
export type Term = (typeof TermObject)[keyof typeof TermObject];
/**
 * Represents a request to update an item in a shopping cart.
 */
export interface UpdateItemRequest extends Parsable {
    /**
     * Gets or sets the new quantity of the product in the cart.
     */
    quantity?: number;
}
export interface UpdatePriceTierRequest extends Parsable {
    /**
     * The currencyCode property
     */
    currencyCode?: string;
    /**
     * The newName property
     */
    newName?: string;
    /**
     * The oldName property
     */
    oldName?: string;
}
export interface User extends Parsable {
    /**
     * Gets or sets the email of the sales representative.
     */
    email?: string;
    /**
     * Gets or sets the first name of the sales representative.
     */
    firstName?: string;
    /**
     * Gets or sets the last name of the sales representative.
     */
    lastName?: string;
    /**
     * Gets or sets the password of the sales representative, this will be ignored if they already have an existing identity account.
     */
    password?: string;
    /**
     * Gets or sets the phone number of the sales representative.
     */
    phoneNumber?: string;
}
export interface UserExistsResponse extends Parsable {
    /**
     * The userExists property
     */
    userExists?: boolean;
}
export type UserTypes = (typeof UserTypesObject)[keyof typeof UserTypesObject];
export const AddressTypeObject = {
    Company: "Company",
    Billing: "Billing",
    Shipping: "Shipping",
} as const;
export const ContactTypeObject = {
    Company: "Company",
    Billing: "Billing",
    Shipping: "Shipping",
    Buyer: "Buyer",
} as const;
export const CurrencyCodeObject = {
    USD: "USD",
    CAD: "CAD",
    MXN: "MXN",
    EUR: "EUR",
} as const;
export const ImageFormatObject = {
    Unknown: "unknown",
    Jpg: "jpg",
    Png: "png",
    Jpeg: "jpeg",
    Webp: "webp",
    Gif: "gif",
    Svg: "svg",
} as const;
export const ProductTypeObject = {
    DefaultEscaped: "Default",
} as const;
export const TermObject = {
    NET15: "NET15",
    NET30: "NET30",
    NET45: "NET45",
    NET60: "NET60",
    NET90: "NET90",
    NET120: "NET120",
    CREDIT: "CREDIT",
    CASH: "CASH",
    COD: "COD",
    PREPAID: "PREPAID",
} as const;
export const UserTypesObject = {
    None: "None",
    SalesRep: "SalesRep",
    Customer: "Customer",
    Employee: "Employee",
} as const;
/* tslint:enable */
/* eslint-enable */
