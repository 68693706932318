import { defineStore } from 'pinia';
import { getCurrentInstance, Ref, ref, UnwrapRef, watch } from 'vue';
import { saveCurrentOrg, getCurrentOrg } from '@/stores/localStorageService';
import { handleGlobalError } from '@/composables/globalErrorHandler';
import router from '@/router';
import { GetOrganizationResponse, ListUserOrganizationsResponse, Organization } from '@/api-client/models';
import { ApiClientFactory } from '@/stores/apiClientFactory';
import { KindeClient, KindeUser } from '@kinde-oss/kinde-auth-pkce-js';
import { aU } from '@fullcalendar/core/internal-common';

export const useAuthStore = defineStore('authStore', () => {
    const instance = getCurrentInstance();
    const kinde: KindeClient = instance?.appContext.config.globalProperties.$kinde;
    let kindeUser: Ref<KindeUser> = ref<KindeUser>({ given_name: null, id: null, family_name: null, email: null, picture: null });
    const clientFactory = new ApiClientFactory();
    const client = clientFactory.createClient();
    
    const organizations = ref<Array<Organization>>([]);
    const organization = ref<GetOrganizationResponse>({});
    const authenticated = ref(false);
    const roles = ref<Array<string>>([]);
    const admin = ref(false);
    const accessToken: Ref<UnwrapRef<string>> = ref('');
    const currentOrgId = ref('');
    const currentOrg = ref('');
    
    const init = async () => {
        console.log('init authStore');
        try {
            authenticated.value = await kinde.isAuthenticated();
        }
        catch (error: any) {
            authenticated.value = false;
            window.location.href = '/home';
            handleGlobalError(error);
        }
        
        if (authenticated.value) {
            kindeUser.value = await kinde.getUser();
            accessToken.value = <string>await kinde.getToken();
       
            currentOrgId.value =<string> kinde.getClaim("org_code")?.value;
            currentOrg.value = <string> kinde.getClaim("org_name")?.value; 
           const roles =  kinde.getClaim("roles")?.value;
           
            for (const role of roles) {
                if (role.key === 'admin') {
                    admin.value = true;
                    break;
                }
            }
        }
        
        console.log('orgs', await kinde.getUserOrganizations());

        if (authenticated.value) {
            router.push('/');
        }
    };

    init();

    let loginConfig = {};
    if (currentOrg != null) {
        loginConfig = {
            organization: currentOrg
        };
    }

    const getAuthorizationToken = async () => {
        console.log('getAuthorizationToken');
        return accessToken.value;
    };

    const logUserIn = async () => {
        console.log('logUserIn');
        try {
            await kinde.login({
                app_state: {
                    redirectTo: window.location
                }
            });
        }
        catch (error: any) {
         //   handleGlobalError(error);
            window.location.href = '/home';
            //TODO: this fixes auth initialization but it's not the right way to do it
        }
        

        /*
    loginWithRedirect({
      authorizationParams: loginConfig
    });
     */
    };
    const signupUser = () => {
        kinde.login();
    };

    const logUserOut = async () => {
        await kinde.logout();
    };

    const changeOrganization = (org_id: string) => {
        //TODO: verify this 
        saveCurrentOrg(org_id);
        currentOrgId.value = org_id;
        getOrganization();
    };

    const getOrganization = async () => {
        try {
            const response = await client.api.organization.get();
            console.log('getOrganization:', response);
            organization.value = response?.organization ?? {};
        } catch (error: any) {
            handleGlobalError(error);
        }
    };

    const getOrganizations = async () => {
        try {
            const response: ListUserOrganizationsResponse | undefined = await client.api.users.organizations.get();
            organizations.value = response?.organizations ?? [];
            console.log('getOrganizations:', response);

            currentOrgId.value = organizations.value[0].identityOrganizationId ?? '';
            saveCurrentOrg(currentOrgId.value);
        } catch (error: any) {
            handleGlobalError(error);
        }
    };

    /*
  const validateAccountSetup = async () => {
    return;
    //TODO: finish account setup
    if (organization.value.organization?.accountSetup?.isComplete)
      return;

    // Get the next step that is incomplete in account.steps
    const nextStep = organization.value.organization?.accountSetup?.steps?.find((step) => !step.isComplete);
    console.log('nextStep:', nextStep);
    if (nextStep?.stepId === 'choose-subscription') {
      console.log('redirecting to subscription page');
      // redirect to subscription page
      router.push({ name: 'subscribe' });
    }
  }*/

    const getPortalSessionLink = async () => {
        try {
            const response = await client.api.organizations.subscriptions.portalSessionLink.get();
            console.log('getProtalSessionLink:', response);
            return response;
        } catch (error: any) {
            handleGlobalError(error);
        }
    };

    const getReadmeLoginUrl = async () => {
        try {
            const response = await client.api.auth.readme.get();
            console.log('getProtalSessionLink:', response);
            return response;
        } catch (error: any) {
            handleGlobalError(error);
        }
    };

    return {
        logUserIn,
        signupUser,
        logUserOut,
        changeOrganization,
        getAuthorizationToken,
        getPortalSessionLink,
        getReadmeLoginUrl,
        User: kindeUser,
        roles,
        admin,
        authenticated,
        organizations,
        currentOrg,
        currentOrgId
    };
});
